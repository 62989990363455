import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { Column } from "primereact/column";
import { GLOBAL_DOMAIN, Api_Headers } from '../../Config/Config';
// import  ProfileSVG  from '../../Assets/profile.svg';
import axios from 'axios';
//import { Card } from 'primereact/card';
//import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useHistory, Link } from 'react-router-dom';
import {ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './UserProfile.css';
import DeliveryAddress from '../Payments/DeliveryAddress/DeliveryAddress';
import { BreadCrumb } from 'primereact/breadcrumb';
import BreadCrumbCard from '../../Components/Breadcrumb/Breadcrumb';
import Account_Circle_Icon from '../../Assets/Atum_Icons/Account_circle_icon.svg'; 

const UserProfile = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [current_password, setCurrent_Password] = useState('');
//    const [new_password, setNew_Password] = useState('');
    const [confirm_password, setConfirm_Password] = useState('');
    const [userData, setUserData] = useState({});
    const [userAddresses, setUserAddresses] = useState([]);
    const [editstatus, seteditstatus] = useState(false);
    const [changepassword, setchangepassword] = useState(false);
    const [PasswordFormData, setPasswordFormData] = useState({})
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const history = useHistory();
    const breadcrumb_data = [
        {label: 'My Account'},
    ];

    useEffect(() => {
        getUserInfo();
        getUserAddress();
    }, []);

    // const passwordForm  = () => {
    //     PasswordForm.setFieldValue('CURRENT_PASSWORD',Password.CURRENT_PASSWORD)
    //     PasswordForm.setFieldValue('NEW_PASSWORD',Password.NEW_PASSWORD)
    //     PasswordForm.setFieldValue('CONFIRM_PASSWORD',Password.CONFIRM_PASSWORD)
    // }

    const PasswordForm = useFormik({

        initialValues: {
            CURRENT_PASSWORD: "",
            NEW_PASSWORD: "",
            CONFIRM_PASSWORD: "",
        },
        validate: (data) => {
            let errors = {};
            if (!data.CURRENT_PASSWORD) {
                errors.CURRENT_PASSWORD = 'CURRENT PASSWORD is required.';
            }
            if (!data.NEW_PASSWORD) {
                errors.NEW_PASSWORD = 'NEW PASSWORD is required.';
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(data.NEW_PASSWORD)) {
                errors.NEW_PASSWORD = 'Invalid Valid Password like "Password@123"';
            }
            if (!data.CONFIRM_PASSWORD) {
                errors.CONFIRM_PASSWORD = 'CONFIRM PASSWORD is required.';
            }
            if (data.NEW_PASSWORD && data.CONFIRM_PASSWORD) {
                if (data.NEW_PASSWORD !== data.CONFIRM_PASSWORD) {
                  errors.CONFIRM_PASSWORD = "Password not matched";
                }
              }
            return errors;
        },
        onSubmit: (data) => {
            setPasswordFormData(data);
            console.log('Password From Data', data)
            updatePassword()
        }
    })

    const resetaddress = () => {
        PasswordForm.resetForm();
    }

    const isFormFieldValid = (name) => !!(PasswordForm.touched[name] && PasswordForm.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{PasswordForm.errors[name]}</small>;
    };

    const getUserInfo = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
        }).then((res) => {
            console.log(res);
            setUserData(res.data);
            setFirstName(res.data.FIRST_NAME);
            setLastName(res.data.LAST_NAME);
            setEmail(res.data.EMAIL);
            setMobileNumber(res.data.MOBILE_NUMBER);
        }).catch((err) => {
            console.log(err);
        });
    }
    const getUserAddress = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses?type=list&limit=100&userId=' + user_data.data.userData.ID,
        }).then((res) => {
            setUserAddresses(res.data.rows);
            // console.log('enduseraddressses==============', res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    }
    // const submitHandler = () => {

    // };

    
    const updateProfile = () => {
        if(changepassword){
            let obj = {
                'CURRENT_PASSWORD': current_password,
                'NEW_PASSWORD': confirm_password 
            }
        }
        let obj = {
              'FIRST_NAME': firstName,
              'LAST_NAME': lastName,
              'MOBILE_NUMBER': mobileNumber,
              'EMAIL': email,
        };
        axios({
            method: 'PUT',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
            data: obj,
        }).then((res) => {
            console.log(res.data);
            seteditstatus(false);
        }).catch((err) => {
            console.log('Update password',err);
        });
    };

    const updatePassword = () => {
            let obj = {
                'CURRENT_PASSWORD': PasswordFormData.CURRENT_PASSWORD,
                'NEW_PASSWORD': PasswordFormData.CONFIRM_PASSWORD 
            } 
        axios({
            method: 'PATCH',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
            data: obj,
        }).then((res) => {
            console.log(res.data);
            seteditstatus(false);
            resetaddress();
        }).catch((err) => {
            console.log(err);
        });
    };

    const accept = () => {
        axios({
            method: 'PATCH',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID + '?action=deleteuser',
        }).then((res) => {
            console.log(res.data);
            window.localStorage.clear();
            // setTimeout(() => {
                history.push('/login');
            //   }, 3000);
            
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        }).catch((err) => {
            console.log(err);
        });
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const DeleteAccount = () => {
        confirmDialog({
            message: 'Do you want to delete Your Account?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }
    return (
        <section className=''>
        <Container>
        <ConfirmDialog />
        {/* <div className='mb-4'>
        <Row>
            <Col sm={12} md={10}>
                <Row>
                    <Col sm={12} md={4} className=''>
                        <Card className='d-flex justify-items-center justify-content-center'>
                        <img src={Account_Circle_Icon} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                        <p style={{width:'120px'}} className='text-center'>Anith Kumar Pitla </p>
                        </Card>
                    </Col>
                    <Col sm={12} md={8}>
                        <Card>
                        <Form className=''>
                        <Row>
                            <Col md={8}>
                                <Form.Group className="input_field mb-3" controlId='name'>
                                    <label htmlFor="First_Name" className="d-block mb-1">First Name</label>
                                    <InputText
                                        type='text'
                                        className='form-control'
                                        id = 'First_Name'
                                        placeholder='Enter your First name'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        disabled = {!editstatus}
                                    ></InputText>
                                </Form.Group>
                            </Col>
                            <Col md={4} className='mt-2 d-flex align-items-center'>
                                <Button>Edit</Button>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="input_field mb-3" controlId='name'>
                                    <label htmlFor="Last_Name" className="d-block mb-1">Last Name</label>
                                    <InputText
                                        type='text'
                                        id = 'Last_Name'
                                        className='form-control'
                                        placeholder='Enter your Last name'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        disabled = {!editstatus}
                                    ></InputText>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group className="input_field mb-3" controlId='email'>
                                    <label htmlFor="Email" className="d-block mb-1">Email</label>
                                    <InputText
                                        type='email'
                                        id = 'Email'
                                        className='form-control'
                                        placeholder='Enter email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled
                                    ></InputText>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group className="input_field mb-3" controlId='tel'>
                                    <label htmlFor="Phone_Number" className="d-block mb-1">Phone Number</label>
                                    <InputText
                                        type='tel'
                                        id = 'Phone_Number'
                                        className='form-control'
                                        placeholder='Enter Mobile No.'
                                        maxlength="10"
                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        disabled
                                    ></InputText>
                                </Form.Group>
                            </Col>
                         </Row>
                         {changepassword && 
                        <div>
                            <hr/>
                            <Form.Group className="input_field mb-3" controlId='name'>
                                    <label htmlFor="CURRENT_PASSWORD" className="d-block mb-1">Current Password</label>
                                    <InputText
                                        type='text'
                                        id = 'CURRENT_PASSWORD'
                                        placeholder='Enter your Current Password'
                                        value={PasswordForm.values.CURRENT_PASSWORD}
                                        onChange={PasswordForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('CURRENT_PASSWORD') }))}
                                    ></InputText>
                            <small>{getFormErrorMessage('CURRENT_PASSWORD')}</small>

                                </Form.Group>
                                <Form.Group className="input_field mb-3" controlId='name'>
                                    <label htmlFor="NEW_PASSWORD" className="d-block mb-1">New Password</label>
                                    <InputText
                                        type='text'
                                        id = 'NEW_PASSWORD'
                                        placeholder='Enter your New Password'
                                        value={PasswordForm.values.NEW_PASSWORD}
                                        onChange={PasswordForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('NEW_PASSWORD') }))}
                                    ></InputText>
                            <small>{getFormErrorMessage('NEW_PASSWORD')}</small>

                                </Form.Group>
                                <Form.Group className="input_field mb-3" controlId='name'>
                                    <label htmlFor="CONFIRM_PASSWORD" className="d-block mb-1">Confirm Password</label>
                                    <InputText
                                        type='Password'
                                        id = 'CONFIRM_PASSWORD'
                                        placeholder='Re Enter your Password'
                                        value={PasswordForm.values.CONFIRM_PASSWORD}
                                        onChange={PasswordForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('CONFIRM_PASSWORD') }))}
                                    ></InputText>
                            <small>{getFormErrorMessage('CONFIRM_PASSWORD')}</small>

                                </Form.Group>
                        </div>
                        }
                        {editstatus == true &&
                        <div>
                        <div className='mb-3' style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button className='form-control btn_p mx-2' variant="success" onClick={() => updateProfile()}>
                                Update
                            </Button>
                            <Button className='form-control btn_p mx-2' variant="success" onClick={() => seteditstatus(false)}>
                                Cancel
                            </Button>
                        </div>
                        <hr/>
                        <div className='d-flex justify-content-between'>
                            <div><p className='change_password' onClick={() => setchangepassword(true) || seteditstatus(false)}>Change Password</p></div>
                            <div><p className='color_red cursor_poniter' onClick={() => DeleteAccount()}><span><i className="pi pi-trash mx-1"></i></span>Delete Account</p></div>
                        </div>
                        </div>
                        }
                        {changepassword &&
                        <div className='mb-3 d-flex justify-content-center'>
                           <Button className='form-control btn_p mx-2' variant="success" onClick={PasswordForm.handleSubmit}>
                                Update
                            </Button>
                            <Button className='form-control btn_p mx-2' variant="success" onClick={() => seteditstatus(false) || setchangepassword(false)}>
                                Cancel
                            </Button>
                        </div>
                        }
                       
                    </Form>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col sm={12} md={2}></Col>
        </Row>
        </div> */}
            <Row>
                {/* <Col md={12}>
                <i className="pi pi-user" style={{'fontSize': '2em'}}></i>
                    <h4 style={{ marginTop: '2%' }}>
                        <Image src={ProfileSVG} style={{ fill: '#77cab9', width: '60px', border: '2px solid #77cab9', padding: '3px', borderRadius: '50px', marginRight: '3%'}} /> 
                        <sapn>{userData !== '' ? userData.FIRST_NAME : 'Guest'}</sapn>
                    </h4>
                </Col> */}
                <Col md={4} className="order-section card p-3 h-50" style={{ 
                    overflow: 'auto',
                    position: 'sticky',
                    top: '10%'}}>
                    <div className='d-flex justify-content-between mb-3'>
                        <div className='d-flex'>
                    <i className="pi pi-user" style={{'fontSize': '2em'}}></i>
                    <h4 className='mx-3'>
                        {/* <Image src={ProfileSVG} style={{ fill: '#77cab9', width: '60px', border: '2px solid #77cab9', padding: '3px', borderRadius: '50px', marginRight: '3%'}} />  */}
                        <sapn>{userData !== '' ? userData.FIRST_NAME : 'Guest'}</sapn>
                    </h4>
                    </div>
                    <div className='d-flex align-items-center'>
                    <i className="pi pi-user-edit" style={{'fontSize': '1.5em'}} onClick={() => seteditstatus(true)}></i>
                    </div>
                    </div>
                    <Form className=''>
                        {/* <Row>
                            <Col md={12}> */}
                                <Form.Group className="field mb-3" controlId='name'>
                                    {/* <Form.Label>First Name</Form.Label> */}
                                    <label htmlFor="First_Name" className="d-block mb-1">First Name</label>
                                    <InputText
                                        type='text'
                                        className='form-control'
                                        id = 'First_Name'
                                        placeholder='Enter your First name'
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        disabled = {!editstatus}
                                    ></InputText>
                                </Form.Group>
                            {/* </Col> */}

                            {/* <Col md={12}> */}
                                <Form.Group className="field mb-3" controlId='name'>
                                    {/* <Form.Label>Last Name</Form.Label> */}
                                    <label htmlFor="Last_Name" className="d-block mb-1">Last Name</label>
                                    <InputText
                                        type='text'
                                        id = 'Last_Name'
                                        className='form-control'
                                        placeholder='Enter your Last name'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        disabled = {!editstatus}
                                    ></InputText>
                                </Form.Group>
                            {/* </Col> */}

                            {/* <Col md={12}> */}
                                <Form.Group className="field mb-3" controlId='email'>
                                    {/* <Form.Label>Email Address</Form.Label> */}
                                    <label htmlFor="Email" className="d-block mb-1">Email</label>
                                    <InputText
                                        type='email'
                                        id = 'Email'
                                        className='form-control'
                                        placeholder='Enter email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled
                                    ></InputText>
                                </Form.Group>
                            {/* </Col> */}

                            {/* <Col md={12}> */}
                                <Form.Group className="field mb-3" controlId='tel'>
                                    {/* <Form.Label>Mobile Number</Form.Label> */}
                                    <label htmlFor="Phone_Number" className="d-block mb-1">Phone Number</label>
                                    <InputText
                                        type='tel'
                                        id = 'Phone_Number'
                                        className='form-control'
                                        placeholder='Enter Mobile No.'
                                        maxlength="10"
                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        disabled
                                    ></InputText>
                                </Form.Group>
                            {/* </Col>
                        </Row> */}
                         {changepassword && 
                        <div>
                            <hr/>
                            <Form.Group className="field mb-3" controlId='name'>
                                    {/* <Form.Label>First Name</Form.Label> */}
                                    <label htmlFor="CURRENT_PASSWORD" className="d-block mb-1">Current Password</label>
                                    <InputText
                                        type='text'
                                        // className='form-control'
                                        id = 'CURRENT_PASSWORD'
                                        placeholder='Enter your Current Password'
                                        value={PasswordForm.values.CURRENT_PASSWORD}
                                        onChange={PasswordForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('CURRENT_PASSWORD') }))}
                                    ></InputText>
                            <small>{getFormErrorMessage('CURRENT_PASSWORD')}</small>

                                </Form.Group>
                                <Form.Group className="field mb-3" controlId='name'>
                                    {/* <Form.Label>First Name</Form.Label> */}
                                    <label htmlFor="NEW_PASSWORD" className="d-block mb-1">New Password</label>
                                    <InputText
                                        type='text'
                                        // className='form-control'
                                        id = 'NEW_PASSWORD'
                                        placeholder='Enter your New Password'
                                        value={PasswordForm.values.NEW_PASSWORD}
                                        onChange={PasswordForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('NEW_PASSWORD') }))}
                                    ></InputText>
                            <small>{getFormErrorMessage('NEW_PASSWORD')}</small>

                                </Form.Group>
                                <Form.Group className="field mb-3" controlId='name'>
                                    {/* <Form.Label>First Name</Form.Label> */}
                                    <label htmlFor="CONFIRM_PASSWORD" className="d-block mb-1">Confirm Password</label>
                                    <InputText
                                        type='Password'
                                        // className='w-100'
                                        id = 'CONFIRM_PASSWORD'
                                        placeholder='Re Enter your Password'
                                        value={PasswordForm.values.CONFIRM_PASSWORD}
                                        onChange={PasswordForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('CONFIRM_PASSWORD') }))}
                                    ></InputText>
                            <small>{getFormErrorMessage('CONFIRM_PASSWORD')}</small>

                                </Form.Group>
                        </div>
                        }
                        {editstatus == true &&
                        <div>
                        <div className='mb-3' style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button className='form-control btn_p mx-2' variant="success" onClick={() => updateProfile()}>
                                Update
                            </Button>
                            <Button className='form-control btn_p mx-2' variant="success" onClick={() => seteditstatus(false)}>
                                Cancel
                            </Button>
                        </div>
                        <hr/>
                        <div className='d-flex justify-content-between'>
                            <div><p className='change_password' onClick={() => setchangepassword(true) || seteditstatus(false)}>Change Password</p></div>
                            <div><p className='color_red cursor_poniter' onClick={() => DeleteAccount()}><span><i className="pi pi-trash mx-1"></i></span>Delete Account</p></div>
                        </div>
                        </div>
                        }
                        {changepassword &&
                        <div className='mb-3 d-flex justify-content-center'>
                           <Button className='form-control btn_p mx-2' variant="success" onClick={PasswordForm.handleSubmit}>
                                Update
                            </Button>
                            <Button className='form-control btn_p mx-2' variant="success" onClick={() => seteditstatus(false) || setchangepassword(false)}>
                                Cancel
                            </Button>
                        </div>
                        }
                       
                    </Form>
                </Col>
                <Col xs={12} md={8}>
                    {/* <DataTable value={userAddresses}  >
                        <Column header='Address Line 1' field="ADDRESS_LINE1" />
                        <Column header='Address Line 2' field='ADDRESS_LINE2' />
                        <Column header='City' field='CITY' />
                        <Column header='State' field='STATE' />
                        <Column header='Country' field='COUNTRY' />
                        <Column header='Pincode' field='PINCODE' />
                        <Column header='Contact No' field='MOBILE_NUMBER' />
                    </DataTable> */}
                    <div className='row mx-3'>
                        {/* <h4 className='mb-3'>Delivery Address</h4> */}
                    {/* {userAddresses.map(item => {
                    return (
                        <Col xs={6} md={4} className="mb-3">
                            <Card className='text-break overflow-auto' style={{height:'200px'}}>
                                <h6 className="text-truncate">{item.CUSTOMER_NAME}</h6>
                                <p className='text-justify'>
                                    {item.ADDRESS_LINE1},
                                    {item.ADDRESS_LINE2},
                                    {item.CITY},
                                    {item.STATE},
                                    {item.COUNTRY},
                                    {item.PINCODE}.
                                </p>
                                <p><b>PH :</b> {item.MOBILE_NUMBER}</p>
                            </Card>
                        </Col>
                    )
                }
                )} */}
                <DeliveryAddress/>
                {/* {userAddresses.length == 0 && 
                <div>No Delivery Address Found</div>
                } */}
                    </div>
                </Col>
            </Row>
        </Container>
        </section>
    )
}
export default UserProfile;