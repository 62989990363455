import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import Header from "../../Components/Header/Header";
import { Form, Button, Row, Col, Carousel, Container } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { useHistory, Link } from 'react-router-dom';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers } from '../../Config/Config';
import './Cart.css';

const Cart = () => {
    const history = useHistory();
    const [CartData, setCartdata] = useState([]);
    const [Cart_Percentage_Limit, setCart_Percentage_Limit] = useState(Number);
    const [Cart_Quantity_Limit, setCart_Quantity_Limit] = useState(Number);
    const [Quantity_Limit_Error, setQuantity_Limit_Error] = useState(false);
    const [totaldiscountquantity, settotaldiscountquantity] = useState(false);
    const [select, setSelect] = useState('');
    const [totaldiscount, settotaldiscountamount] = useState();
    const [totalamount, settotalamount] = useState();
    const [Total_Discounted_amount, setTotal_Discounted_amount] = useState();
    const [ORDER_TOTAL_AMOUNT, setORDER_TOTAL_AMOUNT] = useState();
    const [total_extracharge, settotal_extracharge] = useState();
    const [openDel, setDel] = useState(false);
    const [Price, setPrice] = useState();
    let qnty = 0, price = 0, discountprice = 0;
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));

    useEffect(() => {
        Getcartlist();
        // console.log(JSON.parse(productdta));
    }, []);

    const Getcartlist = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/usercart?type=list&limit=50&user_id=' + user_data.data.userData.ID,

        }).then((res) => {
            // console.log('Product response :', res.data);
            // const productdata = res.data;
            setCartdata(res.data.rows);
            settotaldiscountamount(res.data.TOTAL_DISCOUNTED_AMOUNT);
            settotalamount(res.data.TOTAL_AMOUNT);
            setORDER_TOTAL_AMOUNT(res.data.ORDER_TOTAL_AMOUNT);
            setTotal_Discounted_amount(res.data.TOTAL_DISCOUNTED_AMOUNT)
            setCart_Percentage_Limit(res.data.PERCENTAGE_LIMIT);
            setCart_Quantity_Limit(res.data.QUANTITY_LIMIT);
            settotal_extracharge(res.data.TOTAL_EXTRA_CHARGES);
            // console.log('CART Dataaa :', CartData);

        }).catch((err) => {
            console.log(err);
        });
    }

    const quantityCost = (items) => {
        if (items.DISCOUNT_PERCENTAGE == 0) {
            price += items.QUANTITY * items.PRICE;
        } else {
            price += items.QUANTITY * items.DISCOUNT_PRICE
        }
        // localStorage.setItem('p_data', price);
        // setPrice(price);
        qnty += items.QUANTITY;
        // discountprice = price - (discountprice + it.DISCOUNT_PRICE);
        // localStorage.setItem('cartPrice', price);
        // console.log(price);
    };

    const quantityCheck = (item, type) => {
        let newState = [...CartData], index = CartData.findIndex((obj => obj.ID === item.ID));

        if (type === 'plus') {
            if (item.PRODUCTS_QUANTITY < newState[index].QUANTITY) {
                setQuantity_Limit_Error(true);
                // console.log("Quantity true")
            }
            if (Cart_Percentage_Limit <= item.DISCOUNT_PERCENTAGE) {
                if (newState[index].QUANTITY < 5) {
                    newState[index].QUANTITY = item.QUANTITY + 1;
                    settotaldiscountquantity(true);
                    // console.log("working discount");
                } else {
                    newState[index].QUANTITY = item.QUANTITY
                    settotaldiscountquantity(false);
                    // console.log("discount left");
                }
            } else {
                settotaldiscountquantity(false);
                newState[index].QUANTITY = item.QUANTITY + 1;
            }

            axios({
                method: 'patch',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/usercart/' + item.ID,
                data: { 'INCREMENT': true }
            }).then((res) => {
                // console.log(res);
                setCartdata(newState);
                Getcartlist();
                // console.log(price)
            }).catch((err) => {
                console.log(err);
            });

        } else if (type === 'minus') {
            // console.log(item.QUANTITY)
            if (item.PRODUCTS_QUANTITY > newState[index].QUANTITY) {
                setQuantity_Limit_Error(false);
                // console.log("Quantity false")
            } else {
                // if(item.QUANTITY < newState[index].QUANTITY){
                setQuantity_Limit_Error(true);
                // console.log("Quantity true")
                // }
            }
            if (item.QUANTITY > 1) {
                newState[index].QUANTITY = item.QUANTITY - 1;

                axios({
                    method: 'patch',
                    headers: Api_Headers,
                    url: GLOBAL_DOMAIN + '/v1/usercart/' + item.ID,
                    data: { 'DECREMENT': true }
                }).then((res) => {
                    // console.log(res); setCartdata(newState);
                    Getcartlist();
                }).catch((err) => {
                    // console.log(err);
                });
            }else{
                removeCart(item)
            }
        }
    };

    const removeCart = (it) => {
        setSelect(it);
        removeItem()
    };

    const removeItem = () => {
        axios({
            method: 'delete',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/usercart/' + select.ID,
        }).then((res) => {
            axios({
                method: 'get',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/usercart?type=list&limit=500&user_id=${userdata.ID}'
            }).then((res) => {
                // console.log(res.data.rows);
                //  setCartdata(res.data.rows);
                // window.localStorage.setItem('cartInfo', res.data.rows.length);
            }).catch((err) => {
                console.log(err);
            });
            Getcartlist();
            setDel(false); setSelect('');
        }).catch((err) => {
            console.log(err);
        });
    };
    const footer = (
        <div className='d-flex justify-content-between'>
            <div>Total Price</div>
            <div>{Price}</div>
        </div>
    );

    const gotoSO = () => {
        // const data = { User_Cart_Price: ORDER_TOTAL_AMOUNT }
        history.push('/submitorder')
    }

    return (
        <section className='container'>
            <div className='row'>
                {/* Cart List  */}
                <div className='col-sm-8'>
                    {CartData.map((item, i) => {
                        quantityCost(item)
                        const header = (
                            <img alt="Card" className="img-fluid card-img-top" src={IMG_GLOBAL + item.IMAGE_PATH1} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                        );
                        const pricedata = (
                            <div>
                                {/* {item.isspecialAttributesAvailable == true && */}
                                <div className='status_button'>
                                    {(item.PRICE - item.DISCOUNT_PRICE) == 0 ? <p className="color_green m-0">&#8377;{item.PRICE}</p> :
                                        <p className="color_green m-0"> &#8377;{item.DISCOUNT_PRICE} <del className="color_red">&#8377;{item.PRICE}</del></p>}
                                </div>
                                {/* } */}
                            </div>
                        );
                        const sub_Total = (
                            <div>
                                {/* {item.isspecialAttributesAvailable == true && */}
                                <div className='status_button'>
                                    {/* {item.DISCOUNT_PERCENTAGE === 0 && item.isattributesAvailable && <p className="color_green m-0 fs-9"><b>&#8377;{(item.PRICE * item.QUANTITY) + parseFloat(item.extraCharges)}</b></p>} */}
                                    {<p className="color_green m-0 fs-9"><b> &#8377;{((parseFloat(item.DISCOUNT_PRICE) * parseInt(item.QUANTITY)) + parseFloat(item.extraCharges)).toFixed(2)}</b></p>}
                                </div>
                                {/* } */}
                            </div>
                        );
                        const Atum_coins = (
                            <div className='status_button'>
                                {/* {item.ELIGIBLE_ATUM_COINS === 0 && <p className="color_head">&#8377;{item.PRICE}</p>} */}
                                {item.ELIGIBLE_ATUM_COINS > 0 && <p className="color_head">*Get {item.ELIGIBLE_ATUM_COINS} ATUM COINS Cashback</p>}
                            </div>
                        );
                        return (
                            <Card className='mb-3'>
                                <div className='row' key={i}>
                                    <div className='col-sm-3'>
                                        <img
                                            alt="LOGO"
                                            src={IMG_GLOBAL + (item.isspecialAttributesAvailable ? item.specialAttributesInfo.IMAGE_PATH : item.IMAGE_PATH1)}
                                            width='100%'
                                            // height='50vh'
                                            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                            className="d-inline-block align-top"
                                            style={{ height: '100px' }}
                                        />
                                    </div>
                                    <div className='col-sm-9'>
                                        <div className='row'>
                                            <div className='col-sm-9 col-md-8 col-lg-9'>
                                                <h4 className='text-truncate'>{item.PRODUCT_NAME}</h4>
                                                <p className='m-0'>{item.CATEGORY_DISPLAY_NAME}</p>
                                                <div className='row cart_flex'>
                                                    <div className='col-sm-6'>
                                                        <p className=''>{pricedata}</p>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        {item.IS_COLOR_VARIANT == 1 &&
                                                            <div>
                                                                <div className='Attribute_color_card text-center d-flex'>
                                                                    <p className=''>Color:</p>
                                                                    <p className='Attribute_color d-inline-flex' style={{ backgroundColor: item.PRODUCT_COLOR }}></p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {item.isattributesAvailable &&
                                                    <div className='row'>
                                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                                            <div className='row'>
                                                                {item.attributesInfo.EXTRA_CHARGES && item.attributesInfo.EXTRA_CHARGES.sort((a, b) => a.ATTRIBUTE_DISPLAY_NAME > b.ATTRIBUTE_DISPLAY_NAME ? 1 : -1).map((items, i) => {
                                                                    return (
                                                                        <div className='col-sm-6' key={i}>
                                                                            <div>
                                                                                <p className='fs-9 m-0'>{items.ATTRIBUTE_DISPLAY_NAME}
                                                                                    {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME == 'GST' &&
                                                                                        <span>({items.MEASUREMENT_VALUE}%)</span>
                                                                                    }<span className='mx-1'>:</span>
                                                                                    {items.MEASUREMENT_NAME == 'AMOUNT' &&
                                                                                        <span>&#8377;{items.MEASUREMENT_VALUE}</span>
                                                                                    }
                                                                                    {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME != 'GST' &&
                                                                                        <span>{items.MEASUREMENT_VALUE} %</span>
                                                                                    }
                                                                                    {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME == 'GST' &&
                                                                                        <span>&#8377;{((parseFloat(item.DISCOUNT_PRICE) * parseFloat(items.MEASUREMENT_VALUE)) / 100).toFixed(2)} </span>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>
                                                }

                                            </div>
                                            <div className='col-sm-3 col-md-4 col-lg-3'>
                                                <div className='cart_flex mt-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <Button className='Icon_Btn Icon_Btn_Min'  onClick={() => quantityCheck(item, 'minus')}>-</Button>
                                                        <div className='Icon_input'><input className='text-center' value={item.QUANTITY} readOnly={true} name="cart_count" type="text" style={{ width: '50px', height: '25px' }} /></div>
                                                        <Button className='Icon_Btn Icon_Btn_Max'  disabled={(item.QUANTITY >= item.PRODUCTS_QUANTITY) || (Cart_Percentage_Limit <= item.DISCOUNT_PERCENTAGE && item.QUANTITY >= 5)} onClick={() => quantityCheck(item, 'plus')}>+</Button>
                                                    </div>
                                                    {/* <div className='d-flex'>
                                                        <Button className='Icon_Btn' disabled={item.QUANTITY == 1} onClick={() => quantityCheck(item, 'minus')}><i className='pi pi-minus-circle my-1' style={{ cursor: 'pointer', color: '#338D7E' }} /></Button>
                                                        <input className='mx-2 text-center' value={item.QUANTITY} readOnly={true} name="cart_count" type="text" style={{ width: '50px', height: '25px' }} />
                                                        <Button className='Icon_Btn' disabled={(item.QUANTITY >= item.PRODUCTS_QUANTITY) || (Cart_Percentage_Limit <= item.DISCOUNT_PERCENTAGE && item.QUANTITY >= 5)} onClick={() => quantityCheck(item, 'plus')}><i className='pi pi-plus-circle my-1' style={{ cursor: 'pointer', color: '#338D7E' }} /> </Button>
                                                    </div> */}
                                                    <div className='d-flex justify-content-center text-center'>
                                                        <Button className="btn_p fs-12 mt-3 px-4" onClick={() => removeCart(item)}>Remove</Button>
                                                    </div>
                                                </div>
                                                <div className='mt-3 d-flex'>
                                                    <p className='fs-9 m-0'>Total:</p>
                                                    <p className='mx-1 m-0'>{sub_Total}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className=''>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* {item.isattributesAvailable && item.CATEGORY=="ELECTRIC_VECHICLES" &&
                                <p className='bg_color_head px-3 text-white border_radius_10'>Prebooking is Available for this Product</p>
                                } */}
                                {Cart_Percentage_Limit <= item.DISCOUNT_PERCENTAGE && item.QUANTITY >= 5 &&
                                    <small className='d-flex justify-content-end'>Offer is applicable for only 5 items</small>
                                }
                                {item.QUANTITY >= item.PRODUCTS_QUANTITY &&
                                    <small className='d-flex justify-content-end'>No Enough Product are available in the Store</small>
                                }
                            </Card>
                        )
                    }
                    )}
                    {CartData.length == 0 &&
                        <div>
                            <h4 className='text-error'>No Product are added to Cart</h4>
                            <p className='text-error'>Please add the Product from <Link to='/dashboard' className='color_head'>Product List</Link></p>
                        </div>
                    }
                </div>

                {/* Price Details Card */}
                <div className='col-sm-4'>
                    <Card title="Price Details" style={{ marginBottom: '2em' }}>
                        <div className='d-flex justify-content-between'>
                            <div>Price{(CartData.total_count)}</div>
                            <div>&#8377;<span className='mx-1'>{totalamount}</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Discount Amount</div>
                            <div className='color_red'>(-) &#8377;<span className='mx-1'>{(parseFloat(totalamount) - parseFloat(totaldiscount)).toFixed(2)}</span></div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Extra Charges</div>
                            <div className='color_head'>(+) &#8377;<span className='mx-1'>{parseFloat(total_extracharge)}</span></div>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between'>
                            <div><b>Total Amount</b></div>
                            <div className='color_green'><b>&#8377;<span className='mx-1'>{parseFloat(ORDER_TOTAL_AMOUNT)}</span></b></div>
                        </div>
                    </Card>
                    {price != 0 &&
                        <div className='d-flex justify-content-end'>
                            <Button className="btn_p fs-12 px-4" disabled={Quantity_Limit_Error} onClick={() => gotoSO()} >Submit Order</Button>
                        </div>
                    }
                </div>
            </div>
            <Dialog
                visible={openDel} onHide={() => { setDel(false); setSelect('') }} style={{ width: '40vw' }}
                header={<h4>Do you want to remove from cart?</h4>}>
                <div className='d-flex justify-content-center'>
                    <div className='mx-2'>
                        <div style={{ cursor: 'pointer' }} onClick={() => { setDel(false); setSelect('') }}>No</div>
                    </div>
                    <div className='mx-2'>
                        <div style={{ color: 'red', cursor: 'pointer' }} onClick={removeItem}>Yes</div>
                    </div>
                </div>
            </Dialog>
        </section>
    )
}

export default Cart;