import React, { useState, useEffect } from 'react';
//import { Carousel } from 'primereact/carousel';
import axios from 'axios';
import '../Home.css';
import { useHistory, Link } from 'react-router-dom';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers } from "../../../Config/Config";
import { Skeleton } from 'primereact/skeleton';
import { Row, Col } from 'react-bootstrap';

const Categories = () => {
    const history = useHistory();
    const [categories_data, setCategories] = useState([]);
    const [isSkeleton, setisSkeleton] = useState(true);
    const [isSkeletonloop, setisSkeletonloop] = useState([1, 2, 3, 4, 5, 6]);

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 6,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '480px',
            numVisible: 2,
            numScroll: 2
        }
    ];
    // const [GetCat, setCatId]=useState(null);
    const getCatID = (CatID) => {
        // setCatId(GetCat.ID)
        window.localStorage.setItem('CATID', JSON.stringify(CatID));
        history.push('/dashboard');
    }
    const getCategories = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/categories?type=list&limit=500&endUsers=true',
        }).then((res) => {
            // console.log('Categories Data :', res.data);
            setCategories(res.data.rows);
            setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        getCategories();
    }, []);

    return (
        <div className="mt-5 bg-color">
            {/* <div className='mx-5 mb-4'>
                <h4>Categories</h4>
            </div> */}
            {/* {isSkeleton ? <Carousel value={isSkeletonloop} numVisible={9} numScroll={3} showIndicators={false} responsiveOptions={responsiveOptions}
                itemTemplate={Skeletontem} />
                :
                <Carousel value={categories_data} numVisible={9} numScroll={3} showIndicators={false} responsiveOptions={responsiveOptions}
                    itemTemplate={productTemplate} />
            } */}
            <div className='container py-4'>
                <Row className='scrollbar_hidden'>
                    {isSkeleton ?
                        <React.Fragment>
                            {isSkeletonloop.map((index) => {
                                return (
                                    <Col md={3} lg={2} sm={6} key={index}>
                                        <div className='m-3'><Skeleton size="140px" shape="circle" /></div>
                                    </Col>
                                )
                            })}
                        </React.Fragment> :
                        <React.Fragment>
                            {categories_data.map((category_data, index) => {
                                return (
                                    <Col md={3} lg={2} sm={6} key={index}>
                                        <div className="product-item">
                                            <div className="product-item-content text-center " onClick={() => getCatID(category_data)}>
                                                <div className="">
                                                    <img src={IMG_GLOBAL + category_data.IMAGE_PATH + category_data.IMAGE_NAME} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt='img' height='140px' width='140px' className="product-image category_item" style={{}} />
                                                </div>
                                            </div>
                                            <div className='text-center mt-2'>
                                                <p className="m-0 text-white text-truncate">{category_data.CATEGORY_DISPLAY_NAME}</p>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </React.Fragment>
                    }
                </Row>
            </div>
        </div>
    )
}
export default Categories; 