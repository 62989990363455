import Home  from '../Pages/Home/Home';
import Dashboard from '../Pages/Dashboard/Dashboard';
//import Login from '../Pages/Login/Login';
//import Signup from '../Pages/Signup/signup';
import Productlist from '../Pages/Products/Productlist/Productlist';
import Header from '../Components/Header/Header';
import UserProfile from '../Pages/UserProfile/UserProfile';
import Wallet from '../Pages/Payments/Wallet/Wallet';
import Productview from '../Pages/Products/Productview/Productview';
import Cart from '../Pages/Cart/Cart';
import Submitorder from '../Pages/Submitorder/Submitorder';
import Orderlist from '../Pages/Orderlist/Orderlist';
import AboutScreen from '../Components/Footer/About';
import ContactScreen from '../Components/Footer/Contact';
import Delivery from '../Components/Footer/Delivery';
import Terms from '../Components/Footer/Terms';
import Exchange from '../Components/Footer/Exchange';
import Privacy from '../Components/Footer/Privacy';
import Errorpage from '../Components/Errorpage';
import Blog from '../Pages/Blog/Blog';
import Notification from '../Pages/Notification/Notification';
import Forgotpassword from '../Pages/Login/Forgotpassword';
import PrebookingOrder from '../Pages/Submitorder/Prebookingorder/Prebookingorder';
import OrderConfirmation from '../Pages/Submitorder/OrderConfirmation/OrderConfirmation';

const routes = [
  {
    path: "/home",
    component: Home
  },
  {
    path: "/header",
    component: Header
  },
  {
    path: "/forgot",
    component: Forgotpassword
  },
  {
    path: "/dashboard",
    component: Dashboard
  },
  // {
  //   path: "/login",
  //   component: Login
  // },
  // {
  //   path: "/signup",
  //   component: Signup
  // },
  {
    path: "/productslist",
    component: Productlist
  },
  {
    path: "/product",
    component: Productview
  },
  {
    path: "/profile",
    component: UserProfile
  },
  {
    path: "/wallet",
    component: Wallet
  },
  {
    path: "/cart",
    component: Cart
  },
  {
    path: "/submitorder",
    component: Submitorder
  },
  {
    path:'/prebooking',
    component: PrebookingOrder
  },
  {
    path: "/orderlist",
    component: Orderlist
  },
  {
    path: '/about',
    component: AboutScreen
  },
  {
    path: '/contact',
    component: ContactScreen
  },
  {
    path: '/terms-and-conditions',
    component: Terms
  },
  {
    path: '/privacy-policy',
    component: Privacy
  },
  {
    path: '/delivery-policy',
    component: Delivery
  },
  {
    path: '/exchanges-and-returns',
    component: Exchange
  },
  {
    path: '/error',
    component: Errorpage
  },
  {
    path: '/blog',
    component: Blog
  },
  {
    path: '/notification',
    component: Notification
  },
  {
    path: '/orderConfromation',
    component: OrderConfirmation
  },
  {
    path:'**',
    component: Errorpage
  }
];

export default routes;