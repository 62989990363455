import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Footer.css';
import Logo from '../../Assets/logo_white.svg';
// import playstore from '../../Assets/palystore.jpg';
// import appstore from '../../Assets/appstore.jpg';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import androidQR from '../../Assets/atum-life-android.png';
import iosQR from '../../Assets/atum-life-ios.png';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import Phone_White_Icon from '../../Assets/icons/phone_white_icon.svg';
import Mail_White_Icon from '../../Assets/icons/mail_white_icon.svg';
import Copyright_White_Icon from '../../Assets/icons/copyright_white_icon.svg'

const Footer = () => {
  const dateyear = new Date().getFullYear();
  const [displayPosition, setDisplayPosition] = useState('');
  const [Dialogs, setDialogs] = useState(false);
  const [formData, setFormData] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: ''
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = 'Name is required.';
      }
      if (!data.email) {
        errors.email = 'Email is required.';
      } else if (! /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(data.email)) {
        errors.email = 'Invalid Email ID';
      }
      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      formik.resetForm();
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  // if (displayPosition == 'playstore') {
  //   window.open('https://play.google.com/store/apps/details?id=com.atumlife');
  // } else {
  //   window.open('https://apps.apple.com/in/app/atumlife/id1662492936 ');
  // }
  return (
    <section className='footer_section' style={{ backgroundColor: '#006b5d', color: '#fff' }}>
      <Container className='py-4'>
        <Card className='py-4 rounded'>
          <div>
            <div className='text-center'>
              <h2>Subscribe to our news letters</h2>
            </div>
            <div className='py-4'>
              <form onSubmit={formik.handleSubmit} className="d-flex justify-content-center align-items-center" >
                <Row style={{width:'85%'}}>
                  <Col sm={12} md={5}>
                    <div className="field">
                      <label htmlFor="name" className={`mb-1 ${classNames({ 'p-error': isFormFieldValid('name') })}`}>Your Name</label>
                      <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                      {getFormErrorMessage('name')}
                    </div>
                  </Col>
                  <Col sm={12} md={5}>
                    <div className="field">
                      <label htmlFor="email" className={`mb-1 ${classNames({ 'p-error': isFormFieldValid('email') })}`}>Email Id</label>

                      <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                      {getFormErrorMessage('email')}
                    </div>
                  </Col>
                  <Col sm={12} md={2}>
                    <div className='primary_btn text-center'>
                      <Button type="submit" label="Join Now" className="p-button mt-4" />
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Card>
      </Container>
      <Container>
        <Row>
          <Col xs={12} md={3} className='mt-3'>
            <img src={Logo} style={{ width: '150px' }} />
            <div className='mt-2 fs-14'>
              <p><small>Mother Earth is loving, caring, nurturing and providing. She always puts you and your needs first. Now, the time has come to protect our home.
                The time has come to return Mother Earth’s unconditional love.</small></p>
            </div>
            <div className='mt-3 fs-14'>
            <img src={Phone_White_Icon} className="text-white"/><span className='mx-2'>8341122922</span>
            </div>
            <div className='mt-3 fs-14'>
            <img src={Mail_White_Icon} className="text-white"/> <span className='mx-2'>info@atumlife.com</span>
            </div>
          </Col>

          <Col xs={12} md={3}>
            <div className='mt-5 d-flex justify-content-center'>
              <div>
                <h5 className=''>Information</h5>
                <ul className='fs-14 mt-4 list-unstyled lh-lg'>
                  <li><Link to="/home" className='hovers'>Home</Link></li>
                  <li><Link to="/about" className='hovers'>About Us</Link></li>
                  <li><Link to="/blog" className='hovers'>Blog</Link></li>
                  <li><Link to="/contact" className='hovers'>Contact Us</Link></li>
                </ul>
              </div>
            </div>
          </Col>

          <Col xs={12} md={3}>
            <div className='mt-5 d-flex justify-content-center'>
              <div>
                <h5 className=''>Policies</h5>
                <ul className='fs-14 mt-4 list-unstyled lh-lg'>
                  <li><Link to="/terms-and-conditions" className='hovers'>Terms & Conditions</Link></li>
                  <li><Link to="/privacy-policy" className='hovers'>Privacy Policy</Link></li>
                  <li><Link to="/delivery-policy" className='hovers'>Delivery Policy</Link></li>
                  <li><Link to="/exchanges-and-returns" className='hovers'>Exchanges & Returns</Link></li>
                </ul>
              </div>
            </div>
          </Col>

          <Col xs={12} md={3}>
            <div className='mt-5 d-flex justify-content-center'>
              <div>
                <h5 className=''>Our Store</h5>
                <ul className='fs-14 mt-4 list-unstyled lh-lg'>
                  <li className=''>
                    <Link to='/contact' className='hovers'> Hyderabad.</Link>
                  </li>
                  <li className=''>
                    <Link to='/contact' className='hovers'> Bangalore.</Link>
                  </li>
                  <li className=''>
                    <Link to='/contact' className='hovers'> Ahmedabad.</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <hr className='mt-5'/>
        <Row className='pb-3 d-flex align-items-center'>
        <Col md={6}>
            <div className='d-flex justify-content-start'>
            <Link to={{ pathname: 'https://www.facebook.com/AtumLife/' }} target='_blank'>
              <div className='pi pi-facebook icon_color'></div>
            </Link>
            <Link to={{ pathname: 'https://twitter.com/AtumLife' }} target='_blank'>
              <div className='pi pi-twitter icon_color'></div>
            </Link>
            <Link to={{ pathname: 'https://www.linkedin.com/company/atumlife/' }} target='_blank'>
              <div className='pi pi-linkedin icon_color'></div>
            </Link>
            <Link to={{ pathname: 'https://www.instagram.com/atumlife/' }} target='_blank'>
              <div className='pi pi-instagram icon_color'></div>
            </Link>
            <Link to={{ pathname: 'https://youtube.com/channel/UCTdTnQED4zghyIUsr_O7dkg' }} target='_blank'>
              <i className='pi pi-youtube icon_color' />
            </Link>
            </div>
          </Col>
          <Col md={6}>
            <div className='d-flex justify-content-end'>
            <p className='m-0'> <img src={Copyright_White_Icon} /> {dateyear} All copyrights reserved to Atum Life</p>
            </div>
          </Col>
          {/* <Col md={2} className='store_link'>
            <div>
            <img className='mx-2 my-1' src={playstore} width={'100px'} onClick={() => setDisplayPosition('playstore') || setDialogs(true)} style={{ borderRadius: '5px' }}></img>
            <img className='mx-2 my-1' src={appstore} width={'100px'} onClick={() => setDisplayPosition('appstore') || setDialogs(true)} style={{ borderRadius: '5px' }}></img>
            </div>
          </Col>
           */}
        </Row>
      </Container>
      <Dialog header="Please scan the QR code to install application" position={'center'} visible={Dialogs} style={{ width: '30vw' }} onHide={() => setDialogs(false)}>
        {/* <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="field">
                <span className="p-float-label">
                <small htmlFor="in">Mobile Number</small>
                  <InputText id="mobile_number" name='mobile_number' type='text' value={formik.values.mobile_number} maxlength={10} minlength={10} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('mobile_number') })} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} onChange={formik.handleChange} />
                  <small>{getFormErrorMessage('mobile_number')}</small>
                  <p><small className='fs-12 color_head'><b>Enter your mobile number to get app store link for insatllation</b></small></p>
                  </span>
                  </div>
                  <div className="field text-end">
                  <Button type='sumbit' className='btn_p'>Submit</Button>
                  </div>
                  </form> */}
        {displayPosition == 'playstore' ? <div className='d-flex justify-content-center'><img src={androidQR} width={'250px'}></img></div>
          : <div className='d-flex justify-content-center'><img src={iosQR} width={'250px'}></img></div>}
      </Dialog>
    </section>
  );
};

export default Footer;