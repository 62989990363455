import React, { Component, useState, useEffect, useRef, componentDidMount } from 'react';
import axios from 'axios';
import { Card } from 'primereact/card';
// import { useHistory, Link, useLocation } from 'react-router-dom';
import { GLOBAL_DOMAIN, Api_Headers } from "../../Config/Config";
// import { Form, Button, Row, Col, Carousel, Container } from "react-bootstrap";
import './Dashboard.css'
import { Toast } from 'primereact/toast';
import Errorpage from "../../Components/Errorpage";
import Item_Card from '../../Components/Cards/Item_Card';
// import Header_Carousel from '../../Components/Carousel/Header_Carousel';
// import { Skeleton } from 'primereact/skeleton';
import {
    useComponentDidMount,
    useComponentDidUpdate,
    useComponentWillUnmount
  } from "../../Components/utils";

const Dashboard = () => {

    const [banner_data, setbanners] = useState([]);
    const [isSkeleton, setisSkeleton] = useState(true);
    const [productData, setproductdata] = useState([]);
    const [catflag, setcatflag] = useState(false);
    var getcatID = JSON.parse(window.localStorage.getItem('CATID'));
    const toast = useRef(null);
    const search = window.localStorage.getItem('search_data');
    const [apierrorstatus, setapierrorstatus] = useState(false);
    const store_Id = window.localStorage.getItem('Store_ID');
    // const {GRTCAT_ID} = location.state;

    useEffect(() => {
        // getCategories();
        window.scrollTo(0, 0)
        getbanner();
    }, []);

    useComponentDidMount(() => {
        if(search){
            searchdata(search);
            getcatID='';
        }else{
            getCatlist(getcatID.ID);
        }
      });
    
      useComponentDidUpdate(() => {
        console.log(search);
        if(search){
            searchdata(search);
            getcatID='';
        }else{
            getCatlist(getcatID.ID);
        }
      }, [getcatID.ID, search]);
       
      useComponentWillUnmount (() => {
        if(search){
            searchdata(search);
            getcatID='';
        }else{
            getCatlist(getcatID.ID);
        }
      })
    const getbanner = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/dashboards?type=list&homePage=true&platForm=WEBSITE',

        }).then((res) => {
            setbanners(res.data.PROMOTIONAL_BANNERS);
            setisSkeleton(false);
        }).catch((err) => {
            setTimeout(() => {
                // setapierrorstatus(true);
            }, 10000)
        });
    };
    
    const showtoast = () => {
        toast.current.show({ severity: 'success', summary: 'Successfully added to cart', life: 3000 });
    }
    const searchdata = (search) => {
        // let search = window.localStorage.getItem('search_data')
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/products?type=list&limit=50&endUsers=true&storeId=' + store_Id + '&search=' + search,
        }).then((res) => {
            const productdata = res.data.rows;
            localStorage.removeItem('search_data');
            setproductdata(productdata);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getCatlist = (GetCat) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/products?type=list&limit=500&endUsers=true&categoryId=' + GetCat +'&storeId=' + store_Id + '&search=',
        }).then((res) => {
            const productdata = res.data.rows;
            setproductdata(productdata);
            setcatflag(true);
        }).catch((err) => {
            console.log(err);
        });
    }

    // const getCategories = () => {
    //     axios({
    //         method: 'GET',
    //         url: GLOBAL_DOMAIN + '/v1/categories?type=list&limit=500&endUsers=true',
    //     }).then((res) => {
    //         setCategories(res.data.rows);
    //     }).catch((err) => {
    //         console.log(err);
    //         setTimeout(() =>{
    //             setapierrorstatus(true);
    //         },10000)
    //     });
    // }

    return (
        <section>
            <Toast ref={toast} />
            {apierrorstatus ? <Errorpage /> :
                <div>
                    {/* <div className='bg-color-light'>
                    {isSkeleton && <Skeleton width="100%" height="280px" />}
                    {!isSkeleton && banner_data.length >= 1 && 
                    <Header_Carousel BannerData = {banner_data} />
                    }
                    </div> */}
                    <div className='container layout-main-container mt-4'>
                        {productData.length > 0 && <h5 className="col-sm-6 mb-4 mt-3 px-4 fw-500 py-2 border_radius_10 text-white green_gradient">{productData[0].CATEGORY_DISPLAY_NAME}</h5> }
                        <div className="row">
                            {productData.length <= 0 && <div><h6 className='text-error'>No Products Found</h6></div>}
                            {productData.map((item, index) => {
                                return (
                                        <div className='col-sm-3 mb-4' key={index}>
                                <Item_Card card_format='small' Product_list_data={item} />   
                                </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            }
        </section>
    )
}

export default Dashboard;
