
import React, { useEffect, useState, useRef } from 'react';
// import './AccordionDemo.css';
import axios from 'axios';
import { Card } from 'primereact/card';
import { RadioButton } from 'primereact/radiobutton';
import { Form, Row, Col } from "react-bootstrap";
import { GLOBAL_DOMAIN, IMG_GLOBAL, REFARAL_GLOBAL_DOMAIN, Payment_Gateway, Api_Headers, PAYTM_SCRIPT_ID, MERCHANT_URL } from '../../Config/Config';
import { InputText } from 'primereact/inputtext';
import './Submitorder.css';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import wishlist_icon from '../../Assets/icons/wishlist_icon.svg';
import Delete_icon from '../../Assets/icons/delete_icon.svg';
import Edit_icon from '../../Assets/icons/Edit_icon.svg';
import { Tooltip } from 'primereact/tooltip';
import { Sidebar } from 'primereact/sidebar';
import { InputTextarea } from 'primereact/inputtextarea';
import Location_Icon from '../../Assets/icons/location_icon.svg';
import Phone_Icon from '../../Assets/icons/phone_icon.svg';
import { initializeTPL } from '../../Components/Tpl';

const Submitorder = () => {
    const history = useHistory();
    const [CartData, setCartdata] = useState([]);
    const [userdata, setuserdata] = useState({});
    const [Price, setPrice] = useState();
    const [TotalPrice, setTotalPrice] = useState();
    const [CoustmerMobileNumber, setMobileNumber] = useState(0);
    const [PrimaryAddress, setPrimaryAddress] = useState({});
    const [PrimaryAddressID, setPrimaryAddressID] = useState();
    const [errorPrimaryAddress, seterrorPrimaryAddress] = useState(false);
    const [Multipleaddress, setMultipleaddress] = useState([]);
    const [Deliverymethod, setdeliverymethod] = useState('Home_Delivery');
    const [Deliverystatus, setdeliverystatus] = useState(false);
    // const [multipleaddressflag, setmultiaddressflag] = useState(false);
    const [Paymentmethod, setpaymentmethod] = useState('ONLINE');
    const [paymentStatus, setpayment_status] = useState('');
    const [User_Info, setuserinfo] = useState({});
    const [Dailogflag, setDailogflag] = useState(false);
    const [formData, setFormData] = useState({});
    // const [editaddress, seteditaddress] = useState({});
    const [editaddressflag, seteditaddressflag] = useState(false);
    const [CouponsData, setCouponsData] = useState([]);
    const [referralcouponstatus, setreferralcouponstatus] = useState(false);
    // const [referralcouponstatus_code, setreferralcouponstatus_code] = useState('');
    const [Couponserror, setCouponserror] = useState('');
    const [Couponstatus, setCouponstatus] = useState(true);
    const [SelectedCoupon, setSelectedCoupon] = useState([]);
    const [storepickup, setstorepickup] = useState([]);
    const [SelectedstorepickupID, setSelectedstorepickupID] = useState(0);
    const [SelectedStoreData, setSelectedStoreData] = useState({});
    const [totaldiscountamount, settotaldiscountamount] = useState();
    const [totalamount, settotalamount] = useState();
    const [Dailogcouponflag, setDailogcouponflag] = useState(false)
    const [selected_Coupon_items_data, setselected_Coupon_items_data] = useState([])
    // const { User_Cart_Price } = location.state;
    const [total_extra_charges, settotal_extra_charges] = useState();
    const [Coupon_Alert, setCoupon_Alert] = useState('');
    const [coupondiscountvalue, setcoupondiscountvalue] = useState();
    const [couponsFlag, setcouponsFlag] = useState(false);
    // const [CouponMINValueflag, setCouponMINValueflag] = useState(false)
    const [Coupon_Catogories_List, setCoupon_Catogories_List] = useState([])
    const [Coupon_Products_List, setCoupon_Products_List] = useState([]);
    // const [mobileerrors, setmobileerrors] = useState({});
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const [invalidproduct, setinvalidproduct] = useState(false);
    const [totaldiscountquantity, settotaldiscountquantity] = useState(false);
    const [Quantity_Limit_Error, setQuantity_Limit_Error] = useState(false);
    const [Cart_Quantity_Limit, setCart_Quantity_Limit] = useState(Number);
    const [Cart_Percentage_Limit, setCart_Percentage_Limit] = useState(Number);
    const [noproductserror, setnoproductserror] = useState(false);
    let qnty = 0, price = 0, discountprice = 0;
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    // const [UserMobileNumber, setUserMobileNumber] = useState('');
    const [Mobileupdate, setFormMobileUpdateData] = useState({});
    const [Mobile_Number_Valid, setMobile_Number_Valid] = useState(false);
    const [Update_Mobile_Number, setUpdate_Mobile_Number] = useState();
    const [Storevisible, setStoreVisible] = useState(false);
    const [Delivery_Address_visible, SetDelivery_Address_visible] = useState(false);
    const [Coupon_visible, SetCoupon_visible] = useState(false);

    useEffect(() => {
        setMobileNumber(user_data?.data.userData.MOBILE_NUMBER);
        setuserdata(user_data.data.userData)
        Getcartlist(0);
        getUserInfo();
        if (Deliverymethod == 'Home_Delivery') {
            getAddress('Home_Delivery')
        }
        getstorepickupdata();
//        initializeTPL(PAYTM_SCRIPT_ID);
    }, []);

    

    const Getcartlist = (storeId) => {
        setSelectedstorepickupID(storeId);
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/usercart?type=list&limit=50&user_id=' + user_data.data.userData.ID + '&storeId=' + storeId,
        }).then((res) => {
            console.log(res.data)
            setCartdata(res.data.rows);
            settotaldiscountamount(res.data.TOTAL_DISCOUNTED_AMOUNT);
            settotalamount(res.data.TOTAL_AMOUNT);
            setTotalPrice(res.data.ORDER_TOTAL_AMOUNT);
            setPrice(res.data.ORDER_TOTAL_AMOUNT);
            settotal_extra_charges(res.data.TOTAL_EXTRA_CHARGES);
            setCart_Percentage_Limit(res.data.PERCENTAGE_LIMIT);
            setCart_Quantity_Limit(res.data.QUANTITY_LIMIT);
            if ((res.data.rows[0].CATEGORY == 'ELECTRIC_VECHICLES') && res.data.total_count == 1) {
                setreferralcouponstatus(true);
                // referralcoupon();
            } else {
                getCoupons();
            }
            storepickupvalidation(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getUserInfo = () => {
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
        }).then((res) => {
            console.log(res.data)
            setuserinfo(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const DeliveryMethodMode = (Delivery_mode) => {
        console.log(Delivery_mode)
        setdeliverymethod(Delivery_mode);
        console.log(Deliverymethod);
        getstorepickupdata()
    }

    const getstorepickupdata = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/stores?type=list&limit=20',
        }).then((res) => {
            console.log(res.data.rows)
            setstorepickup(res.data.rows);
            setSelectedstorepickupID(res.data.rows[0].ID);
            setSelectedStoreData(res.data.rows[0]);
            Getcartlist(res.data.rows[0].ID);
            storepickupvalidation(res.data.rows);
            if (CoustmerMobileNumber) {
                Delivery_Mobile_Number.setFieldValue('Delivery_mobile_number', User_Info?.MOBILE_NUMBER)
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const storepickupvalidation = (cartdata) => {
        console.log(Deliverymethod)
        // if (Deliverymethod === 'Store_Pickup') {
        let errorstatusdata = cartdata.filter((errorstatus) => errorstatus.isAvailableInStore == false);
        let errorstatusoutofstock = cartdata.filter((outofstock) => outofstock.PRODUCTS_QUANTITY <= 0);
        // cartdata.find((item, i) => {
        // return (
        console.log(errorstatusdata);
        if (errorstatusdata.length > 0 || errorstatusoutofstock.length > 0) {
            setinvalidproduct(true);
        }
        else {
            setinvalidproduct(false);
        }
        // )
        // })
        // }
        validationerror(cartdata);
    }

    const selectedstorepickdata = (selectedstoredata_ID) => {
        setSelectedstorepickupID(selectedstoredata_ID);
        const SecletedStore_Data = storepickup.find((option) => (option.ID == selectedstoredata_ID));
        setSelectedStoreData(SecletedStore_Data);
        console.log(SecletedStore_Data);
        Getcartlist(selectedstoredata_ID);
    }
    const selectedDeliveryAddressdata = (selecteddelivery_address_ID) => {
        setPrimaryAddressID(selecteddelivery_address_ID)
        const SecletedDeliveryAddress_Data = Multipleaddress.find((option) => (option.ID == selecteddelivery_address_ID));
        setPrimaryAddress(SecletedDeliveryAddress_Data);
    }
    // Product List Data
    const getProductData = () => {
        axios({
            method: 'GET',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/products?type=drop&limit=1000'
        }).then((res) => {
            setCoupon_Products_List(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    // Category List Data
    const getCategoryData = () => {
        axios({
            method: 'GET',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/categories?type=drop&limit=1000'
        }).then((res) => {
            setCoupon_Catogories_List(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const validationerror = (items) => {
        items.map(item => {
            if (item.PRODUCTS_QUANTITY < 0 || item.QUANTITY > item.PRODUCTS_QUANTITY) {
                setnoproductserror(true);
            } else {
                setnoproductserror(false);
            }
        })
    }

    const quantityCost = (items) => {
        if (items.DISCOUNT_PERCENTAGE == 0) {
            price += items.QUANTITY * items.PRICE;
        } else {
            price += items.QUANTITY * items.DISCOUNT_PRICE
        }
        // localStorage.setItem('p_data', price);
        // setPrice(price);
        qnty += items.QUANTITY;
        // discountprice = price - (discountprice + it.DISCOUNT_PRICE);
        // localStorage.setItem('cartPrice', price);
    };

    const quantityCheck = (item, type) => {
        let newState = [...CartData], index = CartData.findIndex((obj => obj.ID === item.ID));

        if (type === 'plus') {
            if (item.isAvailableInStore == false) {
                if (item.PRODUCTS_QUANTITY < newState[index].QUANTITY) {
                    setQuantity_Limit_Error(true);
                }
            }
            else {
                item.STORES_INFO.map((stores, i) => {
                    if (stores.STORE_ID == SelectedstorepickupID) {
                        if (stores.QUANTITY < newState[index].QUANTITY) {
                            setQuantity_Limit_Error(true);
                        }

                    }
                })
                // if (item.STORES_INFO < newState[index].QUANTITY) {
                //     setQuantity_Limit_Error(true);
                //     console.log("Quantity true")
                // }
            }
            if (item.DISCOUNT_TYPE == "PERCENTAGE" && (parseInt(item.DISCOUNT_VALUE) >= Cart_Percentage_Limit)) {

                if (newState[index].QUANTITY < 5) {
                    newState[index].QUANTITY = item.QUANTITY + 1;
                    setQuantity_Limit_Error(true);
                } else {
                    newState[index].QUANTITY = item.QUANTITY
                    setQuantity_Limit_Error(false);
                }
            } else {
                settotaldiscountquantity(false);
                newState[index].QUANTITY = item.QUANTITY + 1;
            }

            axios({
                method: 'patch',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/usercart/' + item.ID,
                data: { 'INCREMENT': true }
            }).then((res) => {
                // setCartdata(newState);
                Getcartlist(SelectedstorepickupID);
            }).catch((err) => {
                console.log(err);
            });

        } else if (type === 'minus') {
            if (item.PRODUCTS_QUANTITY > newState[index].QUANTITY) {
                setQuantity_Limit_Error(false);
            } else {
                // if(item.QUANTITY < newState[index].QUANTITY){
                setQuantity_Limit_Error(true);
                // }
            }
            if (item.QUANTITY > 1) {
                newState[index].QUANTITY = item.QUANTITY - 1;

                axios({
                    method: 'patch',
                    headers: Api_Headers,
                    url: GLOBAL_DOMAIN + '/v1/usercart/' + item.ID,
                    data: { 'DECREMENT': true }
                }).then((res) => {
                    Getcartlist(SelectedstorepickupID);
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                removeCart(item)
            }
        }
    };

    const showInMapClicked = (storepickup) => {
        window.open("https://maps.google.com?q=" + storepickup.LATITUDE + "," + storepickup.LONGITUDE);
    };

    const CouponTypeValueData = (type, row) => {
        getProductData(); getCategoryData();
        let convertarray = JSON.parse("[" + row + "]")
        let obj;
        if (type == "CATEGORIES") {
            obj = Coupon_Catogories_List.filter(it => {
                return convertarray.includes(it.ID)
            })
        } else {
            obj = Coupon_Products_List.filter(it => {
                return convertarray.includes(it.ID)
            })
        }
        return obj.map((items, index) => {
            return (
                <React.Fragment key={index}>
                    type == "CATEGORIES" ?
                    items.CATEGORY :
                    items.PRODUCT_NAME

                </React.Fragment>
            )
        })
    }

    const getAddress = (address) => {
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        setdeliverymethod(address);
        if (address === 'Home_Delivery') {
            axios({
                method: 'GET',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/enduseraddresses?type=list&limit=500&userId=' + user_data.data.userData.ID
            }).then((res) => {
                let objs = null;
                console.log(res.data)
                setMultipleaddress(res.data.rows);
                if (res.data.rows.length > 0) {
                    // for (let i = 0; i < res.data.rows.length; i++) {
                    //     if (res.data.rows[i].IS_PRIMARY == 1) {
                    //         objs = res.data.rows[i];
                    //         setPrimaryAddress(objs);
                    //         seterrorPrimaryAddress(true);
                    //         console.log(PrimaryAddress);
                    //     }
                    // };
                    setPrimaryAddress(res.data.rows[0])
                    setPrimaryAddressID(res.data.rows[0].ID)
                    seterrorPrimaryAddress(true);
                } else {
                    setPrimaryAddress({});
                    seterrorPrimaryAddress(false)
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        else {
            setPrimaryAddress({});
            seterrorPrimaryAddress(false);
        }
    }

    const seteditaddressess = (editaddress) => {
        AddressForm.setFieldValue("CUSTOMER_NAME", editaddress.CUSTOMER_NAME)
        AddressForm.setFieldValue("MOBILE_NUMBER", editaddress.MOBILE_NUMBER)
        AddressForm.setFieldValue("ADDRESS_LINE1", editaddress.ADDRESS_LINE1)
        AddressForm.setFieldValue("ADDRESS_LINE2", editaddress.ADDRESS_LINE2)
        AddressForm.setFieldValue("CITY", editaddress.CITY)
        AddressForm.setFieldValue("STATE", editaddress.STATE)
        AddressForm.setFieldValue("COUNTRY", editaddress.COUNTRY)
        AddressForm.setFieldValue("PINCODE", editaddress.PINCODE)
        AddressForm.setFieldValue("IS_PRIMARY", editaddress.IS_PRIMARY == 1 ? true : false)
    }

    const AddressForm = useFormik({

        initialValues: {
            CUSTOMER_NAME: "",
            MOBILE_NUMBER: "",
            ADDRESS_LINE1: "",
            ADDRESS_LINE2: "",
            CITY: "",
            STATE: "",
            COUNTRY: "",
            PINCODE: "",
            IS_PRIMARY: true
        },
        validate: (data) => {
            let errors = {};
            if (!data.CUSTOMER_NAME) {
                errors.CUSTOMER_NAME = 'Name is required.';
            }
            if (!data.MOBILE_NUMBER) {
                errors.MOBILE_NUMBER = 'Moble number is required.';
            } else if (!/^[6-9]\d{9}$/i.test(data.MOBILE_NUMBER)) {
                errors.MOBILE_NUMBER = 'Invalid Phone Number';
            }
            if (!data.ADDRESS_LINE1) {
                errors.ADDRESS_LINE1 = 'Address is required.';
            }
            if (!data.ADDRESS_LINE2) {
                errors.ADDRESS_LINE2 = 'Address is required.';
            }
            if (!data.CITY) {
                errors.CITY = 'City is required.';
            }
            if (!data.STATE) {
                errors.STATE = 'State is required.';
            }
            if (!data.COUNTRY) {
                errors.COUNTRY = 'Country is required.';
            }
            if (!data.PINCODE) {
                errors.PINCODE = 'Pincode is required.';
            } else if (!/^[1-9][0-9]{5}$/i.test(data.PINCODE)) {
                errors.PINCODE = 'Invalid Pincode';
            }
            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            setPrimaryAddress(data);
            seterrorPrimaryAddress(true)
            if (editaddressflag) {
                editaddresses(data)
            } else {
                formaddress(data);
            }
            resetaddress();
        }
    })

    const resetaddress = () => {
        AddressForm.resetForm();
    }

    const isFormFieldValid = (name) => !!(AddressForm.touched[name] && AddressForm.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{AddressForm.errors[name]}</small>;
    };

    const formaddress = (address) => {
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        let body = Object.assign(address, { USER_ID: user_data.data.userData.ID })
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses',
            data: body
        }).then((res) => {
            setDailogflag(false)
            getAddress('Home_Delivery');
        }).catch((err) => {
            console.log(err);
        });
    }

    const editaddresses = (editdata) => {
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        let body = Object.assign(editdata, { USER_ID: user_data.data.userData.ID })
        axios({
            method: 'PUT',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses/' + PrimaryAddress.ID,
            data: body
        }).then((res) => {
            setDailogflag(false)
            getAddress('Home_Delivery')
        }).catch((err) => {
            console.log(err);
        });
    }

    const deleteaddress = (deletedata) => {
        axios({
            method: 'DELETE',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses/' + deletedata.ID,
            data: {}
        }).then((res) => {
            if (deletedata.ID == PrimaryAddress.ID) {
                setPrimaryAddress({})
                seterrorPrimaryAddress(false)
            }
            getAddress('Home_Delivery');
        }).catch((err) => {
            console.log(err);
        });
    }

    const getCoupons = () => {
        axios({
            method: 'GET',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/coupons?type=list&limit=500&userId=' + user_data.data.userData.ID + '&plotForm=WEBSITE'
        }).then((res) => {
            console.log(res.data.data.rows)
            setCouponsData(res.data.data.rows);
            // setCouponstatus(true);
        }).catch((err) => {
            console.log(err);
        });
    }

    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    const DateFormat = (dt) => {
        let odate = new Date(dt), monArr = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let day = odate.getDate(), month = odate.getMonth(), year = odate.getFullYear();
        return day > 10 ? day + '/' + monArr[month] + '/' + year : '' + day + '/' + monArr[month] + '/' + year;
    };

    const CouponCalculation = (coupon) => {
        if (coupon.COUPON_TYPE == 'CATEGORIES' || coupon.COUPON_TYPE == 'PRODUCTS') {
            // if(!CartData.isCouponApplied)
            let selected_item_value = 0;
            // let selected_items_data = [];
            CartData.map((item) => {
                // return(
                // if(coupon.COUPON_TYPE_VALUES == item.CATEGORY_ID){
                item.COUPON_INFO.map((coupon_data) => {
                    if (coupon.ID == coupon_data.ID) {
                        selected_item_value = selected_item_value + parseFloat(item.DISCOUNT_PRICE * item.QUANTITY);
                        // selected_items_data.push(item.PRODUCT_NAME)
                    }

                })
                // }
                // )
            });
            if (selected_item_value == 0) {
                setDailogcouponflag(true)
                setCoupon_Alert("Applied Coupon is not applicable for Cart Items" + "Add the products from these Categories/Products" + " " + CouponTypeValueData(coupon?.COUPON_TYPE, coupon?.COUPON_TYPE_VALUES));
            } else if (selected_item_value < coupon.MIN_ORDER_VALUE) {
                setDailogcouponflag(true);
                // setCouponMINValueflag(true);
                setCoupon_Alert("Required minimum amount" + " " + coupon.MIN_ORDER_VALUE + " " + " to the product to apply respective Coupon" + "Add the products from these Categories/Products" + " " + CouponTypeValueData(coupon?.COUPON_TYPE, coupon?.COUPON_TYPE_VALUES))
            } else {
                PriceandPercentage(selected_item_value, coupon);
                setcouponsFlag(false)
            }
            // const coupondiscount = selected_item_value* parseInt(coupon.COUPON_VALUE) / 100;
        } else {
            PriceandPercentage(totaldiscountamount, coupon);
            setcouponsFlag(false);
            setselected_Coupon_items_data([]);
        }
    }
    const PriceandPercentage = (CouponValue, coupon) => {
        DispalyappliedCouponitems(coupon);
        if (coupon.COUPON_VALUE_TYPE == 'PERCENTAGE') {
            setCouponstatus(false);
            setSelectedCoupon(coupon);
            const coupondiscount = CouponValue * parseInt(coupon.COUPON_VALUE) / 100;
            const Orderprice = Price - coupondiscount;
            if (coupondiscount > coupon.MAX_DISCOUNT) {
                const Orderprice = Price - coupon.MAX_DISCOUNT;
                setcoupondiscountvalue(coupon.MAX_DISCOUNT);
                setTotalPrice(Orderprice);
            } else {
                setcoupondiscountvalue(coupondiscount);
                setTotalPrice(Orderprice);
            }
        } else {
            const Orderprice = Price - coupon.COUPON_VALUE;
            setcoupondiscountvalue(coupon.COUPON_VALUE);
            setTotalPrice(Orderprice);
            setSelectedCoupon(coupon);
            setCouponstatus(false);
        }
    }
    const DispalyappliedCouponitems = (coupon) => {
        let selected_items_data = [];
        CartData.map((item) => {
            // return(
            // if(coupon.COUPON_TYPE_VALUES == item.CATEGORY_ID){
            item.COUPON_INFO.map((coupon_data) => {
                if (coupon.ID == coupon_data.ID) {
                    // selected_item_value = selected_item_value + parseFloat(item.DISCOUNT_PRICE * item.QUANTITY);
                    selected_items_data.push(item.PRODUCT_NAME)
                }

            })
            // }
            // )
        });
        setselected_Coupon_items_data(selected_items_data);
    }
    const CouponremoveCalculation = () => {
        setTotalPrice(Price);
        setSelectedCoupon('');
        setcoupondiscountvalue('');
        setCouponstatus(true);
        setselected_Coupon_items_data([]);
    }
    const referralcoupon = () => {
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: REFARAL_GLOBAL_DOMAIN + 'v1/userreferralcodes/verifyBikeReferralCode',
            data: {
                // "BIKE_REFERRAL_CODE": referralcouponstatus_code
            }
        }).then((res) => {
            const referralprice = Price - res.data.data.BONUS_TO_NEW_USER;
            setTotalPrice(referralprice);
            setCouponstatus(false);
            setCouponserror('');
        }).catch((err) => {
            // setTotalPrice(Price);
            // setCouponstatus(true);
            setCouponserror(err.response.data.message);
        });
    }
    const removereferralcoupon = () => {
        setTotalPrice(Price);
        setCouponstatus(true);
    }
    const PlaceOrder = () => {
        let OrderData = {
            orderItems: CartData,
            shippingAddress: {
                CUSTOMER_NAME: PrimaryAddress.CUSTOMER_NAME,
                ADDRESS_LINE1: PrimaryAddress.ADDRESS_LINE1,
                ADDRESS_LINE2: PrimaryAddress.ADDRESS_LINE2,
                STATE: PrimaryAddress.STATE,
                CITY: PrimaryAddress.CITY,
                COUNTRY: PrimaryAddress.COUNTRY,
                PINCODE: PrimaryAddress.PINCODE,
                MOBILE_NUMBER: PrimaryAddress.MOBILE_NUMBER
            },
            DELIVERY_TYPE: "CUSTOMER_ADDRESS",
            PLATFORM: "WEBSITE",
            PAYMENT_STATUS: "PENDING",
            PAYMENT_MODE: Paymentmethod,
            ORDER_TRACKER_ID: 0,
            ORDER_AMOUNT: TotalPrice,
            TRANSACTION_TOKEN: "",
            TRANSACTION_RESPONSE_TOKEN: "",
            STORE_ID: 0,
            COUPON_ID: SelectedCoupon.ID,
            COUPON_DISCOUNTED_AMOUNT: coupondiscountvalue
        };

        let PickupData = {
            orderItems: CartData,
            shippingAddress: {},
            CUSTOMER_CONTACT_NUMBER: CoustmerMobileNumber,
            DELIVERY_TYPE: "STORE_PICKUP",
            PLATFORM: "WEBSITE",
            PAYMENT_STATUS: "PENDING",
            PAYMENT_MODE: Paymentmethod,
            ORDER_TRACKER_ID: 0,
            ORDER_AMOUNT: TotalPrice,
            TRANSACTION_TOKEN: "",
            TRANSACTION_RESPONSE_TOKEN: "",
            STORE_ID: SelectedstorepickupID,
            COUPON_ID: SelectedCoupon.ID,
            COUPON_DISCOUNTED_AMOUNT: coupondiscountvalue
        };


        let body = null, address = PrimaryAddress;
        // let cart_price = window.localStorage.getItem('cartPrice');

        if (Paymentmethod == 'COD') {
            // body = {
            //     orderItems: CartData,
            //     shippingAddress: {
            //         ADDRESS_LINE1: PrimaryAddress.ADDRESS_LINE1,
            //         ADDRESS_LINE2: PrimaryAddress.ADDRESS_LINE2,
            //         STATE: PrimaryAddress.STATE,
            //         CITY: PrimaryAddress.CITY,
            //         COUNTRY: PrimaryAddress.COUNTRY,
            //         PINCODE: PrimaryAddress.PINCODE,
            //     },
            //     DELIVERY_TYPE: "CUSTOMER_ADDRESS",
            //     PAYMENT_STATUS: "PENDING",
            //     PAYMENT_MODE: "COD",
            //     ORDER_TRACKER_ID: 0,
            //     TRANSACTION_TOKEN: "",
            //     TRANSACTION_RESPONSE_TOKEN: ""
            // };

            // console.log('cod body:', body);

            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/userorders',
                data: Deliverymethod === 'Home_Delivery' ? OrderData : PickupData,
            }).then((res) => {
                history.push('/orderConfromation', { Order_Data: res.data });
            }).catch((err) => {
                console.log(err);
            });
        }
        else if (Paymentmethod == 'ONLINE') {
            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/paymentgateway/generateWebToken',
                data: {
                    'ORDER_TOTAL_AMOUNT': `${TotalPrice}`,
                    'USER_ID': userdata.ID,
                }
            }).then((res) => {
                const checksumInfo = JSON.parse(res.data.paytmchecksum);
                var config = {
                    "root": "",
                    "style": {
                        "bodyBackgroundColor": "#fff",
                        "bodyColor": "#333",
                        "themeBackgroundColor": "#77cab9",
                        "themeColor": "#fff",
                        "headerBackgroundColor": "#77cab9",
                        "headerColor": "#fff",
                        "errorColor": "red",
                        "successColor": "green",
                        "card": {
                            "padding": "",
                            "backgroundColor": "",
                            "borderRadius": '0px',
                        }
                    },
                    "data": {
                        "orderId": res.data.data.orderInfo.ORDER_ID,
                        "token": res.data.data.txnToken,
                        "tokenType": "TXN_TOKEN",
                        "amount": res.data.data.orderInfo.ORDER_TOTAL_AMOUNT,
                    },
                    "payMode": {
                        "labels": {},
                        "filter": {
                            "exclude": [
                                "WALLET",
                                "BANK_MANDATE",
                                "BANK_TRANSFER",
                                "EMI",
                                "EMI_DC",
                                "MGV",
                                "LOGIN",
                                "PPBL",
                                "PDC",
                                "SC",
                                "BALANCE",
                            ]
                        },
                        "order": [
                            "NB",
                            "CARD",
                            "UPI",
                        ]
                    },
                    "website": "WEB",
                    "flow": "DEFAULT",
                    "merchant": {
                        "mid": Payment_Gateway.MERCHANT_ID,
                        'name': 'ATUM Life',
                        "redirect": false,
                    },
                    "handler": {
                        "transactionStatus": function transactionStatus(paymentStatus) {
                            window.Paytm.CheckoutJS.close();

                            // body = {
                            //     orderItems: CartData,
                            //     shippingAddress: {
                            //         ADDRESS_LINE1: PrimaryAddress.ADDRESS_LINE1,
                            //         ADDRESS_LINE2: PrimaryAddress.ADDRESS_LINE2,
                            //         STATE: PrimaryAddress.STATE,
                            //         CITY: PrimaryAddress.CITY,
                            //         COUNTRY: PrimaryAddress.COUNTRY,
                            //         PINCODE: PrimaryAddress.PINCODE,
                            //     },
                            //     DELIVERY_TYPE: "CUSTOMER_ADDRESS",
                            //     PAYMENT_STATUS: "SUCCESS",
                            //     PAYMENT_MODE: "ONLINE",
                            //     ORDER_TRACKER_ID: res.data.data.orderInfo.ORDER_ID,
                            //     TRANSACTION_TOKEN: "",
                            //     TRANSACTION_RESPONSE_TOKEN: "",
                            //     STORE_ID: SelectedstorepickupID
                            // };
                            // OrderData.PAYMENT_STATUS = paymentStatus.STATUS == 'TXN_SUCCESS' ? 'SUCCESS' : 'PENDING';
                            // PickupData.PAYMENT_STATUS = paymentStatus.STATUS == 'TXN_SUCCESS' ? 'SUCCESS' : 'PENDING';
                            if (paymentStatus.STATUS == 'TXN_SUCCESS') {
                                OrderData.PAYMENT_STATUS = 'SUCCESS';
                                PickupData.PAYMENT_STATUS = 'SUCCESS';
                                axios({
                                    method: 'POST',
                                    headers: Api_Headers,
                                    url: GLOBAL_DOMAIN + '/v1/userorders',
                                    data: Deliverymethod === 'Home_Delivery' ? OrderData : PickupData,
                                }).then((res) => {
                                    history.push('/orderConfromation', { Order_Data: res.data });
                                    // history.push(`/order/${res.data.data.data.ID}`)
                                }).catch((err) => {
                                    console.log(err);
                                });
                            } else {
                                showtoast()
                            }
                        },
                        "notifyMerchant": function notifyMerchant(eventName, data) {
                            window.Paytm.CheckoutJS.close();
                        },
                    },
                };

                if (window.Paytm && window.Paytm.CheckoutJS) {
                    window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                        window.Paytm.CheckoutJS.invoke();
                    }).catch(function onError(err) {
                        console.log("Error => ", err);
                        showtoast()
                    });
                };
            }).catch((err) => {
                console.log(err); window.Paytm.CheckoutJS.invoke();
                showtoast()
            });
        } else if (Paymentmethod == 'ATUM_WALLET') {
            // body = {
            //     orderItems: CartData,
            //     shippingAddress: {
            //         ADDRESS_LINE1: PrimaryAddress.ADDRESS_LINE1,
            //         ADDRESS_LINE2: PrimaryAddress.ADDRESS_LINE2,
            //         STATE: PrimaryAddress.STATE,
            //         CITY: PrimaryAddress.CITY,
            //         COUNTRY: PrimaryAddress.COUNTRY,
            //         PINCODE: PrimaryAddress.PINCODE,
            //     },
            //     DELIVERY_TYPE: "CUSTOMER_ADDRESS",
            //     PAYMENT_STATUS: "SUCCESS",
            //     PAYMENT_MODE: "ATUM_WALLET",
            //     ORDER_TRACKER_ID: 0,
            //     TRANSACTION_TOKEN: "",
            //     TRANSACTION_RESPONSE_TOKEN: ""
            // };
            OrderData.PAYMENT_STATUS = "SUCCESS";
            PickupData.PAYMENT_STATUS = "SUCCESS";
            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/userorders',
                data: Deliverymethod === 'Home_Delivery' ? OrderData : PickupData,
            }).then((res) => {
                // console.log('order placed', res.data.data.data);
                history.push('/orderConfromation', { Order_Data: res.data });
            }).catch((err) => {
                console.log(err);
            })
        }
    };
    const removeCart = (it) => {
        axios({
            method: 'delete',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/usercart/' + it.ID,
        }).then((res) => {
            // console.log(res);
            Getcartlist(SelectedstorepickupID);
            // setDel(false); setSelect('');
        }).catch((err) => {
            console.log(err);
        });
    }

    const showtoast = () => {
        toast.current.show({ severity: 'error', summary: 'Payment Failure', life: 3000 });
    }
    const showConfirmmobiletoast = () => {
        toast.current.show({ severity: 'success', summary: 'Mobile Number is Updated', life: 3000 });
    }
    const showwishlisttoast = () => {
        toast.current.show({ severity: 'success', summary: 'Product Added to Wish List', life: 3000 })
    }

    // const Update_Mobile_Number = useFormik({
    //     initialValues: {
    //         mobile_number: ''
    //     },
    //     validate: (data) => {
    //         let errors = {};

    //         if (!data.mobile_number) {
    //             errors.mobile_number = 'Name is required.';
    //         } else if (!/^[6-9]\d{9}$/i.test(data.mobile_number)) {
    //             errors.mobile_number = 'Invalid Phone Number';
    //         }

    //         return errors;
    //     },
    //     onSubmit: (data) => {
    //         setFormMobileUpdateData(data);
    //         // UpdateUser(data)
    //     }
    // });

    // const isFormMobileFieldValid = (mobile_number) => !!(Update_Mobile_Number.touched[mobile_number] && Update_Mobile_Number.errors[mobile_number]);
    // const getMobileFormErrorMessage = (mobile_number) => {
    //     return isFormFieldValid(mobile_number) && <small className="p-error">{Update_Mobile_Number.errors[mobile_number]}</small>;
    // };

    // Product List Data
    const WishList_Api = (wish_item) => {
        let wishlist_data = {
            "PRODUCT_ID": wish_item.ID,
            "CREATED_BY": user_data.data.userData.ID,
            "UPDATED_BY": user_data.data.userData.ID,
            "USER_ID": user_data.data.userData.ID
        }
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/userswishlist',
            data: wishlist_data
        }).then((res) => {
            setCoupon_Products_List(res.data);
            showwishlisttoast();
        }).catch((err) => {
            console.log(err);
        });
    }

    const UpdateUser = () => {
        let obj = {
            'MOBILE_NUMBER': Update_Mobile_Number,
        };
        axios({
            method: 'PUT',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
            data: obj,
        }).then((res) => {
            console.log(res.data);
            // Update_Mobile_Number.resetForm();
            setVisible(false)
            PlaceOrder();
            showConfirmmobiletoast()
        }).catch((err) => {
            console.log('Update Error', err);
        });
    }

    const Place_Order = () => {
        if (User_Info.MOBILE_NUMBER == null || User_Info.MOBILE_NUMBER == '') {
            if (Deliverymethod === 'Home_Delivery') {
                setUpdate_Mobile_Number(PrimaryAddress.MOBILE_NUMBER)
            } else if (Deliverymethod === 'Store_Pickup') {
                setUpdate_Mobile_Number(CoustmerMobileNumber)
            }
            setTimeout(() => {
                setVisible(true)
            }, 1000);
        } else {
            PlaceOrder();
        }
    }
    const footerMobileupdateContent = (
        <div className='primary_btn text-center'>
            <Button onClick={() => { PlaceOrder(); setVisible(false) }} severity="danger" className="p-button-danger mt-2 justify-content-center">No</Button>
            <Button onClick={() => UpdateUser()} className="p-button mt-2">Yes</Button>
        </div>
    );

    const Delivery_Mobile_Number = useFormik({
        initialValues: {
            Delivery_mobile_number: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.Delivery_mobile_number) {
                errors.Delivery_mobile_number = 'Mobile Number is required.';
                setMobile_Number_Valid(false);
            } else if (!/^[6-9]\d{9}$/i.test(data.Delivery_mobile_number)) {
                errors.Delivery_mobile_number = 'Invalid Phone Number';
            } else if (data.Delivery_mobile_number.length == 10) {
                setMobileNumber(data.Delivery_mobile_number);
                setMobile_Number_Valid(true);
            }else if(data.Delivery_mobile_number.length < 10){
                setMobile_Number_Valid(false);
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: (data) => {
            setFormMobileUpdateData(data);
            setMobileNumber(data.Delivery_mobile_number);
            setMobile_Number_Valid(true);
        }
    });

    const isFormDeliveryMobileFieldValid = (name) => !!(Delivery_Mobile_Number.touched[name] && Delivery_Mobile_Number.touched[name] && Delivery_Mobile_Number.errors[name]);
    const getDeliveryMobileFormErrorMessage = (name) => {
        return isFormDeliveryMobileFieldValid(name) && <small className="p-error">{Delivery_Mobile_Number.errors[name]}</small>;
    };

    const Stores_Header = (items) => {
        return (

            <div className="field-radiobutton">
                <RadioButton inputId="Stores_Data" name="Store" value={items.ID} onChange={(e) => { selectedstorepickdata(e.value); }} checked={SelectedstorepickupID === items.ID} />
                <label htmlFor="Store_Pickup_Store" className='mx-3'>{items.STORE_NAME}</label>
            </div>

        )
    }

    const Delivery_Address_Header = (items) => {
        return (

            <div className="field-radiobutton d-flex justify-content-between">
                <div>
                    <RadioButton inputId="Home_Delivery_Data" name="DeliveryAddress" value={items.ID} onChange={(e) => { selectedDeliveryAddressdata(e.value) }} checked={PrimaryAddressID === items.ID} />
                    <label htmlFor="Home_Delivery" className='mx-3'>{items.CUSTOMER_NAME}</label>
                </div>
                <div>
                    <p className='m-0 color_blue' onClick={() => setDailogflag(true) || seteditaddressflag(true) || seteditaddressess(items)}><img src={Edit_icon} /> Edit</p>
                </div>
            </div>

        )
    }
    return (
        <section className='container'>
            <Toast ref={toast} />
            <Dialog className='colse_icon_remove' footer={footerMobileupdateContent} visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
                <div>
                    <div className='text-center'>
                        <h6>Would you like to update your profile with the mobile number<b className='color_head'> {Update_Mobile_Number} </b> ?</h6>
                    </div>
                    {/* <form className="p-fluid">
                        <div className="field ">
                            <label htmlFor="mobile_number" className={`mb-2 ${classNames({ 'p-error': isFormMobileFieldValid('mobile_number') })}`}>Mobile Number*</label>
                            <InputText keyfilter="int" id="name" name="mobile_number" value={Update_Mobile_Number.values.mobile_number} onChange={Update_Mobile_Number.handleChange} autoFocus style={{ height: '40px' }} className={`w-100 login_input ${classNames({ 'login_input_error': isFormMobileFieldValid('mobile_number') })}`} />
                            {getMobileFormErrorMessage('mobile_number')}
                        </div>
                    </form> */}
                </div>
            </Dialog>
            <div className='Store_Sidebar'>
                <Sidebar className='Sidebar_header' position="right" visible={Storevisible} onHide={() => setStoreVisible(false)}>
                    <h4 className='Sidebar_header_heading'>Store Address</h4>
                    <div className='mt-3'>
                        {storepickup && storepickup.map((Store_data, index) => {
                            return (
                                <div className='Stores_data Card_Order_Process mb-2'>
                                    <Card title={Stores_Header(Store_data)} className='box-shadow-none border border-2'>
                                        <Row className='align-items-center'>
                                            <Col sm={4}>
                                                <div>
                                                    <img alt="Card" className='img-fluid' src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png' height={200} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                                </div>
                                            </Col>
                                            <Col sm={8}>
                                                <div className='py-2 text-break'>
                                                    <div><p className='m-0'>{Store_data.STORE_NAME} {Store_data.ADDRESS_LINE1},{Store_data.ADDRESS_LINE2},{Store_data.CITY},{Store_data.STATE},{Store_data.COUNTRY} {Store_data.PINCODE}</p> </div>
                                                    <div className='d-flex mt-2'>
                                                        <img src={Phone_Icon} />
                                                        <p className='m-0 mx-2'>{Store_data.CONTACT_NUMBER}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )
                        })}
                    </div>
                </Sidebar>
            </div>
            <div className='Delivery_Sidebar'>
                <Sidebar className='Sidebar_header' position="right" visible={Delivery_Address_visible} onHide={() => SetDelivery_Address_visible(false)}>
                    <h4 className='Sidebar_header_heading'>Saved Address</h4>
                    <div className='mt-3'>
                        <div className='d-flex justify-content-center mb-3'>
                            <Button label="Add New Address" onClick={() => setDailogflag(true) || seteditaddressflag(false) || resetaddress()} className="p-button-outlined p-button-success" icon="pi pi-plus-circle" />
                        </div>
                        {Multipleaddress && Multipleaddress.map((Delivery_Address_data, index) => {
                            return (
                                <div className='Stores_data Card_Order_Process mb-2'>
                                    <Card title={Delivery_Address_Header(Delivery_Address_data)} className='box-shadow-none border border-2'>
                                        <div className='py-2 text-break'>
                                            <p><b>Address :</b> {Delivery_Address_data.ADDRESS_LINE1},{Delivery_Address_data.ADDRESS_LINE2},{Delivery_Address_data.CITY},{Delivery_Address_data.STATE},{Delivery_Address_data.COUNTRY},{Delivery_Address_data.PINCODE}</p>
                                            <div>
                                                <div className='d-flex'>
                                                    <img src={Phone_Icon} />
                                                    <p className='m-0 mx-2'>{Delivery_Address_data.MOBILE_NUMBER}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            )
                        })}
                    </div>
                </Sidebar>
            </div>
            <div className='Coupon_Sidebar'>
                <Sidebar className='Sidebar_header' position="right" visible={Coupon_visible} onHide={() => SetCoupon_visible(false)}>
                    <h4 className='Sidebar_header_heading'>Avilable Coupons</h4>
                    <div>
                        <Row className=''>
                            {CouponsData.map((coupon, index) => {
                                const Coupon_Type_Display = (
                                    <span key={index}>
                                        {coupon.COUPON_VALUE_TYPE == 'AMOUNT' ?
                                            <span>&#8377;{coupon.COUPON_VALUE}</span> :
                                            <span>{coupon.COUPON_VALUE} %</span>
                                        }
                                    </span>
                                )
                                const ReadMore = ({ children, index }) => {
                                    const text = children;
                                    const [isReadMore, setIsReadMore] = useState(true);
                                    const toggleReadMore = () => {
                                        setIsReadMore(!isReadMore);
                                    };
                                    return (
                                        <small className="Coupon_text" key={index}>
                                            {isReadMore ? text.slice(0, 20) : text}
                                            <span onClick={toggleReadMore} className="read-or-hide">
                                                {isReadMore ? <small>{text.length > 20 ? "...read more" : ''}</small> : <small>show less</small>}
                                            </span>
                                        </small>
                                    );
                                };
                                return (
                                    // <Col md={6} className="my-2" key={index}>
                                    <div className='mt-3'>
                                        <Card className={`box-shadow-none rounded border border-2 ${(new Date() >= new Date(coupon.END_DATE) || new Date() <= new Date(coupon.START_DATE) || coupon.COUPON_USED == 1 || coupon.MIN_ORDER_VALUE >= (parseFloat(totalamount) - parseFloat(total_extra_charges)) ? 'card_disable' : '')}`}>
                                            <Row items>
                                                <Col sm={8} md={8}>
                                                    <h6>{coupon.COUPON_CODE}<span className='px-2 color_head fs-12 fw-bold'> Save &#8377;{parseInt(coupon.COUPON_VALUE)}</span></h6>
                                                    <small className='text-grey fs-14'>
                                                        <ReadMore>{coupon.DESCRIPTION}</ReadMore>
                                                    </small>
                                                </Col>
                                                <Col sm={4} md={4} className='d-flex align-items-center'>
                                                    <div >
                                                        {!Couponstatus && SelectedCoupon.ID == coupon.ID ? <Button className=" p-button-text p-button-info fs-12" onClick={() => CouponremoveCalculation(coupon)} >Remove</Button> //disabled={new Date() > new Date(coupon.END_DATE) && coupon.MIN_ORDER_VALUE > totaldiscountamount}
                                                            : <Button className="p-button-text p-button-info fs-12" onClick={() => CouponCalculation(coupon)} disabled={new Date() >= new Date(coupon.END_DATE) || new Date() <= new Date(coupon.START_DATE) || coupon.COUPON_USED == 1 || coupon.MIN_ORDER_VALUE > totaldiscountamount} >Apply</Button >
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <h6>{Coupon_Type_Display} Off upto &#8377; {coupon.MAX_DISCOUNT}</h6> */}
                                            {/* <div className='fs-10 d-flex justify-content-between align-items-center'>
                                            <small>Min Order Value {coupon.MIN_ORDER_VALUE}</small>
                                        </div> */}

                                            {/* <p>
                                            Term & Conditions : <ReadMore>{coupon.TERMS_AND_CONDITIONS}</ReadMore>
                                        </p> */}
                                            <Row>
                                                <hr style={{ borderTop: "1px dotted #302b2b" }} className="my-2" />
                                            </Row>
                                            <div className='fs-10 d-flex justify-content-between align-items-center'>
                                                <div>
                                                    {/* <small className='fs-10'>This coupon is for referral bouns</small> */}
                                                    <p className='m-0 fs-12 fw-bold'>Maximum discount: &#8377;{coupon.MAX_DISCOUNT} </p>
                                                </div>

                                            </div>
                                        </Card>
                                    </div>
                                    // </Col>
                                )
                            })
                            }
                            {CouponsData.length <= 0 && <h4 className='text-error'>No Coupons Available</h4>}
                        </Row>
                    </div>
                </Sidebar>
            </div>
            <div className='mb-4'>
                <h4 className='fw-bold'>Your Cart</h4>
            </div>
            <div className="row">
                <div className='col-sm-8'>
                    <div>
                        <div header="Product Details">
                            {CartData.map((item, i) => {
                                quantityCost(item)
                                const header = (
                                    <img alt="Card" className="img-fluid card-img-top" src={IMG_GLOBAL + item.IMAGE_PATH1} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                );
                                const pricedata = (
                                    <div className='status_button'>
                                        {(parseFloat(item.PRICE) - parseFloat(item.DISCOUNT_PRICE)) === 0 ? <p className='m-0'><span className="color_green"> &#8377;{item.PRICE}</span></p> :
                                            <p className='m-0'><span className="color_green">&#8377;{item.DISCOUNT_PRICE} <del className="color_red">&#8377;{item.PRICE}</del></span></p>}
                                    </div>
                                );
                                const Atum_coins = (
                                    <div className='status_button'>
                                        {/* {item.ELIGIBLE_ATUM_COINS === 0 && <p className="color_head">&#8377;{item.PRICE}</p>} */}
                                        {item.ELIGIBLE_ATUM_COINS > 0 && <p className="color_head">*Get {item.ELIGIBLE_ATUM_COINS} ATUM COINS Cashback</p>}
                                    </div>
                                );
                                const discount_view_amount = (
                                    <div className='d-flex justify-content-center'>
                                        <div className='discount_view'>
                                            {item.DISCOUNT_TYPE == "AMOUNT" ?
                                                <div><small>&#8377;{item.DISCOUNT_VALUE} Off</small></div> :
                                                <div><small>{item.DISCOUNT_VALUE}% Off</small></div>
                                            }
                                        </div>
                                    </div>
                                );
                                const Cart_Product_Footer = (
                                    <div className='row pt-2 border-top'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center cursor_poniter' onClick={() => WishList_Api(item)}><img src={wishlist_icon} /> <span className='mx-2 text-grey'>Move to Wishlist </span></div>
                                            <div className='d-flex align-items-center cursor_poniter' onClick={() => removeCart(item)}><img src={Delete_icon} /> <span className='mx-2 text-grey'>Delete </span></div>
                                        </div>
                                    </div>
                                );
                                const Extra_Product_charges = (
                                    <div className='row'>
                                        {item.attributesInfo.EXTRA_CHARGES && item.attributesInfo.EXTRA_CHARGES.sort((a, b) => a.ATTRIBUTE_DISPLAY_NAME > b.ATTRIBUTE_DISPLAY_NAME ? 1 : -1).map((items, i) => {
                                            return (
                                                <div className='col-sm-4' key={i}>
                                                    <div>
                                                        <p className='fs-9 m-0'>{items.ATTRIBUTE_DISPLAY_NAME}
                                                            {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME == 'GST' &&
                                                                <span>({items.MEASUREMENT_VALUE}%)</span>
                                                            }<span className='mx-1'>:</span>
                                                            {items.MEASUREMENT_NAME == 'AMOUNT' &&
                                                                <span className='mx-1'>&#8377;{items.MEASUREMENT_VALUE}</span>
                                                            }
                                                            {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME != 'GST' &&
                                                                <span className='mx-1'>{items.MEASUREMENT_VALUE} %</span>
                                                            }
                                                            {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME == 'GST' &&
                                                                <span>&#8377;{(parseFloat(item.DISCOUNT_PRICE) * parseFloat(items.MEASUREMENT_VALUE)).toFixed(2) / 100} </span>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                );
                                return (
                                    <div className='product_cart_card'>
                                        <Card footer={Cart_Product_Footer} className='mb-3 box-shadow-none border border-2 rounded'>
                                            <div className='row' key={i}>
                                                {Deliverymethod === 'Store_Pickup' && item.isAvailableInStore == false &&
                                                    <div className='col-sm-12 pb-1 color_red'>
                                                        <small>Product is not Available for selected store</small>
                                                    </div>
                                                }
                                                {((item.DISCOUNT_TYPE == "PERCENTAGE" && (parseInt(item.DISCOUNT_VALUE) >= Cart_Percentage_Limit)) && item.QUANTITY >= 5) &&
                                                    <small className='d-flex justify-content-end'>Offer is applicable for only 5 items</small>
                                                }
                                                {(item.QUANTITY >= item.PRODUCTS_QUANTITY && item.isAvailableInStore) &&
                                                    <small className='d-flex justify-content-end'>No Enough Product are available in the Store</small>

                                                }
                                                <div className='col-sm-3'>
                                                    <img
                                                        alt="LOGO"
                                                        src={IMG_GLOBAL + (item.isspecialAttributesAvailable ? item.specialAttributesInfo.IMAGE_PATH : item.IMAGE_PATH1)}
                                                        width='100%'
                                                        // height='50vh'
                                                        onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                                        className="d-inline-block border rounded p-1 align-top"
                                                        style={{ height: '100px' }}
                                                    />
                                                </div>
                                                {/* <div className='col-sm-9'>
                                                <h4>{item.PRODUCT_NAME}</h4>
                                                <p>{item.CATEGORY_DISPLAY_NAME}<span className='mx-2'><b>Quantity:</b> {item.QUANTITY}</span></p>
                                                <p>{pricedata}</p>
                                            </div> */}
                                                <div className='col-sm-9'>
                                                    <div className='row'>
                                                        <p className='text-truncate m-0'>{item.PRODUCT_NAME}</p>
                                                        <div className='col-sm-9 col-md-8 col-lg-9'>
                                                            {item.IS_COLOR_VARIANT == 1 &&
                                                                <div>
                                                                    <div className='Attribute_color_card text-center align-items-center d-flex'>
                                                                        <p className='m-0'>Color:</p>
                                                                        <p className='Attribute_color d-inline-flex' style={{ height: '20px', width: '20px', backgroundColor: item.PRODUCT_COLOR }}></p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className='cart_flex'>
                                                                <div className='d-inline-flex'>
                                                                    <div className='m-0'>{pricedata}</div>
                                                                    {item.DISCOUNT_VALUE > 0 &&
                                                                        <div className='mx-2'>{discount_view_amount}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {item.isattributesAvailable &&
                                                                <div>
                                                                    <Tooltip target=".tooltip-button" autoHide={false}>
                                                                        <div>
                                                                            {item.attributesInfo.EXTRA_CHARGES && item.attributesInfo.EXTRA_CHARGES.sort((a, b) => a.ATTRIBUTE_DISPLAY_NAME > b.ATTRIBUTE_DISPLAY_NAME ? 1 : -1).map((items, i) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div>
                                                                                            <p className='fs-9 m-0'>{items.ATTRIBUTE_DISPLAY_NAME}
                                                                                                {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME == 'GST' &&
                                                                                                    <span>({items.MEASUREMENT_VALUE}%)</span>
                                                                                                }<span className='mx-1'>:</span>
                                                                                                {items.MEASUREMENT_NAME == 'AMOUNT' &&
                                                                                                    <span className='mx-1'>&#8377;{items.MEASUREMENT_VALUE}</span>
                                                                                                }
                                                                                                {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME != 'GST' &&
                                                                                                    <span className='mx-1'>{items.MEASUREMENT_VALUE} %</span>
                                                                                                }
                                                                                                {items.MEASUREMENT_NAME == 'PERCENTAGE' && items.ATTRIBUTE_NAME == 'GST' &&
                                                                                                    <span>&#8377;{(parseFloat(item.DISCOUNT_PRICE) * parseFloat(items.MEASUREMENT_VALUE)).toFixed(2) / 100} </span>
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            )}
                                                                        </div>
                                                                    </Tooltip>
                                                                    <p className='m-0'>Extra Charges : {item.extraCharges} <i className="text-primary pi pi-info-circle tooltip-button" tooltipOptions={{ position: 'top' }}></i></p>
                                                                </div>
                                                            }
                                                            {/* <div className='col-sm-3 col-md-4 col-lg-3 d-flex'>
                                                            <p className='fs-9 m-0'>Payable:</p>
                                                            <p className=' fs-9 m-0 mx-1 color_green'><b>&#8377;{((parseFloat(item.DISCOUNT_PRICE) * parseFloat(item.QUANTITY)) + parseFloat(item.extraCharges)).toFixed(2)}</b></p>
                                                        </div> */}
                                                        </div>
                                                        <div className='col-sm-3 col-md-4 col-lg-3 d-flex align-items-end'>
                                                            <div className='text-bottom'>
                                                                {item.PRODUCTS_QUANTITY > 0 &&
                                                                    <div className='cart_flex '>
                                                                        <p className='mb-1 color_pink'>Qty: {item.PRODUCTS_QUANTITY} Left</p>
                                                                    </div>
                                                                }
                                                                {item.PRODUCTS_QUANTITY > 0 ?
                                                                    <div className='primary_btn d-flex align-items-center'>
                                                                        <Button className='Icon_Btn Icon_Btn_Min justify-content-center' onClick={() => quantityCheck(item, 'minus')}>-</Button>
                                                                        <div className='Icon_input'><input className='text-center' value={item.QUANTITY} readOnly={true} name="cart_count" type="text" style={{ width: '50px', height: '25px' }} /></div>
                                                                        <Button className='Icon_Btn Icon_Btn_Max justify-content-center' disabled={(item.QUANTITY >= item.PRODUCTS_QUANTITY) || ((item.DISCOUNT_TYPE == "PERCENTAGE" && (parseInt(item.DISCOUNT_VALUE) >= Cart_Percentage_Limit)) && item.QUANTITY >= 5)} onClick={() => quantityCheck(item, 'plus')}>+</Button>
                                                                    </div>
                                                                    // <div className='d-flex'>
                                                                    //     <Button className='Icon_Btn' disabled={item.QUANTITY == 1} onClick={() => quantityCheck(item, 'minus')}><i className='pi pi-minus-circle my-1' style={{ cursor: 'pointer', color: '#338D7E' }} /></Button>
                                                                    //     <input className='mx-2 text-center' value={item.QUANTITY} readOnly={true} name="cart_count" type="text" style={{ width: '50px', height: '25px' }} />
                                                                    //     <Button className='Icon_Btn' disabled={(item.QUANTITY >= item.PRODUCTS_QUANTITY) || ((item.DISCOUNT_TYPE == "PERCENTAGE" && (parseInt(item.DISCOUNT_VALUE) >= Cart_Percentage_Limit)) && item.QUANTITY >= 5)} onClick={() => quantityCheck(item, 'plus')}><i className='pi pi-plus-circle my-1' style={{ cursor: 'pointer', color: '#338D7E' }} /> </Button>
                                                                    // </div>
                                                                    : <div>
                                                                        <p>OUT OF Stock</p>
                                                                    </div>
                                                                }
                                                                {/* (item.isAvailableInStore == false && item?.STORES_INFO?.) || */}
                                                                {/* {(item.QUANTITY > item.PRODUCTS_QUANTITY || item.PRODUCTS_QUANTITY <= 0) &&
                                                            <div className='mt-2 primary_btn'>
                                                                <Button className="btn_p fs-12 px-3" onClick={() => removeCart(item)}>Remove</Button>
                                                            </div>
                                                        } */}
                                                            </div>
                                                            {/* <div className='col-sm-3 col-md-4 col-lg-3 d-flex'>
                                                                <p className='fs-9 m-0'>Payable:</p>
                                                                <p className=' fs-9 m-0 mx-1 color_green'><b>&#8377;{((parseFloat(item.DISCOUNT_PRICE) * parseFloat(item.QUANTITY)) + parseFloat(item.extraCharges)).toFixed(2)}</b></p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                            {/* </div> */}
                                        </Card>
                                    </div>
                                )
                            }
                            )}
                        </div>
                        {/* <div header="Delivery Method">
                            <div className="field-radiobutton my-3">
                                <RadioButton inputId="Store_Pickup" name="Deliverymethod" value="Store_Pickup" onChange={(e) => { setdeliverymethod(e.value); setdeliverystatus(false); getstorepickupdata() }} checked={Deliverymethod === 'Store_Pickup'} />
                                <label htmlFor="Store_Pickup" className='mx-3'>Store Pickup</label>
                            </div>
                            <div className="field-radiobutton my-3">
                                <RadioButton inputId="Home_Delivery" name="Deliverymethod" value="Home_Delivery" onChange={(e) => { getAddress(e.value); setdeliverystatus(true); Getcartlist(0) }} checked={Deliverymethod === 'Home_Delivery'} />
                                <label htmlFor="Home_Delivery" className='mx-3'>Home Delivery</label>
                            </div>
                        </div> */}
                        {/* <div header="Delivery Address">
                            {Deliverymethod === 'Store_Pickup' &&
                                <div>
                                    <div>
                                        <form className="p-fluid d-flex justify-content-between">
                                            <div className="field">
                                                <label htmlFor="Delivery_mobile_number" className={`mb-2 ${classNames({ 'p-error': isFormDeliveryMobileFieldValid('Delivery_mobile_number') })}`}>Mobile Number*</label>
                                                <InputText keyfilter="int" id="Delivery_mobile_number" name="Delivery_mobile_number" disabled={Mobile_Number_Valid} value={Delivery_Mobile_Number.values.Delivery_mobile_number} onChange={Delivery_Mobile_Number.handleChange} autoFocus style={{ height: '40px' }} className={`w-100 login_input ${classNames({ 'login_input_error': isFormDeliveryMobileFieldValid('Delivery_mobile_number') })}`} />
                                                {getDeliveryMobileFormErrorMessage('Delivery_mobile_number')}
                                            </div>
                                            <div className='primary_btn d-flex align-items-end'>
                                                <Button type='button' onClick={Delivery_Mobile_Number.handleSubmit} className="justify-content-center fs-10 mx-2">Procced</Button>
                                                <Button type='button' className="justify-content-center fs-10" onClick={() => { setMobile_Number_Valid(false) }} >Edit</Button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='mt-3'>
                                        <Row>
                                            {storepickup ? <h6 className='mb-3'>Store Address</h6> : <h6 className='mb-3'>No Address Found Please add the Address</h6>}
                                            {storepickup.map((item, index) => {
                                                return (
                                                    <Col md={4} className='mb-3' key={index}>
                                                        <Card className={'text-break overflow-auto' + (item.ID == SelectedstorepickupID ? ' card_active' : '')} style={{ height: '200px' }} onClick={() => { { setSelectedstorepickupID(item.ID); selectedstorepickdata(item) } }}>
                                                            <div className='d-flex justify-content-between'>
                                                                <h6>{item.STORE_NAME}</h6>
                                                                <i className="pi pi-map-marker color_head" onClick={() => showInMapClicked(item)}></i>
                                                            </div>
                                                            <p>{item.ADDRESS_LINE1},{item.ADDRESS_LINE2},{item.CITY},{item.STATE},{item.COUNTRY}-{item.PINCODE}</p>
                                                            <p>Mobile Number : {item.CONTACT_NUMBER}</p>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                            }
                                        </Row>
                                    </div>
                                </div>
                            }

                            {Deliverymethod === 'Home_Delivery' &&
                                <div>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <p><b>Name : {PrimaryAddress.CUSTOMER_NAME}</b></p>
                                            <p><b>Phone Number : {PrimaryAddress.MOBILE_NUMBER}</b></p>
                                        </div>
                                        <div className='primary_btn'>
                                            <Button className="fs-10 mx-3" onClick={() => setmultiaddressflag(true)} >Address list</Button>
                                            <Button className="fs-10" onClick={() => setDailogflag(true) || seteditaddressflag(false) || resetaddress()} >Add New</Button>
                                        </div>
                                    </div>
                                    <p><b>Address :</b> {PrimaryAddress.ADDRESS_LINE1},{PrimaryAddress.ADDRESS_LINE1},{PrimaryAddress.CITY},{PrimaryAddress.COUNTRY},{PrimaryAddress.PINCODE}</p>
                                    {multipleaddressflag &&
                                        <Row>
                                            {Multipleaddress ? <h6 className='mb-3'>Yours Address</h6> : <h6 className='mb-3'>No Address Found Please add the Address</h6>}
                                            {Multipleaddress.map((item, index) => {
                                                return (
                                                    <Col md={4} className='mb-3' key={index}>
                                                        <Card className={'text-break overflow-auto' + (item.ID == PrimaryAddress.ID ? ' card_active' : '')} style={{ height: '200px' }} onClick={() => { setPrimaryAddress(item); seterrorPrimaryAddress(true) || setmultiaddressflag(false) }}>
                                                            <div className='d-flex justify-content-between'>
                                                                <div>
                                                                    <h6>{item.CUSTOMER_NAME}</h6>
                                                                </div>
                                                                <div>
                                                                    <i className="pi pi-pencil color_head mx-2" onClick={() => setDailogflag(true) || seteditaddressflag(true) || seteditaddressess(item)}></i>
                                                                    <i className="pi pi-trash color_head mx-2" onClick={() => deleteaddress(item)}></i>
                                                                </div>
                                                            </div>
                                                            <p>{item.ADDRESS_LINE1},{item.ADDRESS_LINE2},{item.CITY},{item.STATE},{item.COUNTRY}-{item.PINCODE}</p>
                                                            <p>Mobile Number : {item.MOBILE_NUMBER}</p>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                            }
                                        </Row>
                                    }
                                </div>
                            }
                        </div> */}
                        {/* <div header="Coupons">
                            {referralcouponstatus &&
                                <div>
                                    <div className='p-inputgroup'>
                                        <InputText
                                            type='text'
                                            className='form-control'
                                            id='coupons'
                                            placeholder='Enter your Coupon'
                                            value={referralcouponstatus_code}
                                            disabled={!Couponstatus}
                                            onChange={(e) => setreferralcouponstatus_code(e.target.value)}
                                        ></InputText>
                                        <div>
                                            {Couponstatus ?
                                                <span className='p-inputgroup-addon primary_btn'><Button className="fs-10 px-4" onClick={() => referralcoupon()} disabled='' >Apply</Button></span>
                                                : <span className='p-inputgroup-addon primary_btn'><Button className="fs-10 px-4" onClick={() => removereferralcoupon()} disabled='' >Remove</Button></span>
                                            }
                                        </div>
                                    </div>
                                    {Couponserror && <small className='color_red'>{Couponserror}</small>}
                                </div>}
                            <Row className=''>
                                {CouponsData.slice(0, 3).map((coupon, index) => {
                                    const Coupon_Type_Display = (
                                        <div>
                                            {coupon.COUPON_VALUE_TYPE == 'AMOUNT' ?
                                                <span>&#8377;{coupon.COUPON_VALUE}</span> :
                                                <span>{coupon.COUPON_VALUE} %</span>
                                            }
                                        </div>
                                    )
                                    return (
                                        <Col md={4} className="my-2" key={index}>
                                            <Card className={(new Date() >= new Date(coupon.END_DATE) || new Date() <= new Date(coupon.START_DATE) || coupon.COUPON_USED == 1 || coupon.MIN_ORDER_VALUE >= (parseFloat(totalamount) - parseFloat(total_extra_charges)) ? 'card_disable' : '')}>
                                                <h6>{Coupon_Type_Display} Off upto &#8377; {coupon.MAX_DISCOUNT}</h6>
                                                <p className='m-0'>{coupon.COUPON_CODE}</p>
                                                <div className='fs-10 d-flex justify-content-between align-items-center'>
                                                    <small>Min Order Value {coupon.MIN_ORDER_VALUE}</small>
                                                    <div className='primary_btn'>
                                                        {!Couponstatus && SelectedCoupon.ID == coupon.ID ? <Button className="fs-10 px-4" onClick={() => CouponremoveCalculation(coupon)} >Remove</Button> //disabled={new Date() > new Date(coupon.END_DATE) && coupon.MIN_ORDER_VALUE > totaldiscountamount}
                                                            : <Button className="fs-10 px-4" onClick={() => CouponCalculation(coupon)} disabled={new Date() >= new Date(coupon.END_DATE) || new Date() <= new Date(coupon.START_DATE) || coupon.COUPON_USED == 1 || coupon.MIN_ORDER_VALUE > totaldiscountamount} >Apply</Button>
                                                        }
                                                    </div>
                                                </div>
                                                <hr style={{ borderTop: "1px dotted #302b2b" }} className="m-2" />
                                                <div>
                                                    <h6 className='m-0'>{coupon.COUPON_TYPE}</h6>
                                                    <p className='m-0 fs-12'>Valid Till : {DateFormat(coupon.END_DATE)} </p>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })
                                }
                                <p className='color_head text-end cursor_poniter' onClick={() => setcouponsFlag(true)}>view more</p>
                                {CouponsData.length <= 0 && <h4 className='text-error'>No Coupons Available</h4>}
                            </Row>
                        </div> */}
                        {/* <div header="Payment Method">
                            <div className="field-radiobutton my-3">
                                <RadioButton inputId="COD" name="Paymentmethod" value="COD" disabled={true} onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')} checked={Paymentmethod === 'COD'} />
                                <label htmlFor="COD" className='mx-3'>Cash On Delivery</label>
                            </div>
                            <div className="field-radiobutton my-3 d-flex justify-content-between">
                                <div>
                                    <RadioButton inputId="ATUM_WALLET" name="Paymentmethod" value="ATUM_WALLET" onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')} checked={Paymentmethod === 'ATUM_WALLET'} />
                                    <label htmlFor="ATUM_WALLET" className='mx-3'>Atum Wallet <b>({parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)})</b></label>
                                </div>
                                {Paymentmethod == 'ATUM_WALLET' && User_Info.TOTAL_WALLET_AMOUNT <= Price ?
                                    <div>
                                        <span className='' style={{ color: 'red' }}><b>{parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)}</b> Please upgrade the amount from <Link id="RouterNavLink" to='/wallet'>Wallet</Link></span>
                                    </div> : ''
                                }
                            </div>
                            <div className="field-radiobutton my-3">
                                <RadioButton inputId="ONLINE" name="Paymentmethod" value="ONLINE" onChange={(e) => setpaymentmethod(e.value) && setpayment_status('SUCCESS')} checked={Paymentmethod === 'ONLINE'} />
                                <label htmlFor="ONLINE" className='mx-3'>Pay Now</label>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='col-sm-4'>

                    {/* Delivery Method card start */}

                    <div className='Delivery_mode Card_Order_Process'>
                        <Card title="Select your delivery mode" className='mb-3 box-shadow-none border rounded border-2'>
                            <div className="Delivery_Method mb-3">
                                <div className='d-flex justify-content-between align-items-center my-2'>
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="Store_Pickup" name="Deliverymethod" value="Store_Pickup" onChange={(e) => { DeliveryMethodMode(e.target.value) }} checked={Deliverymethod === 'Store_Pickup'} />
                                        <label htmlFor="Store_Pickup" className='mx-3'>Store Pickup</label>
                                    </div>
                                    {Deliverymethod == 'Store_Pickup' &&
                                        <div>
                                            <p className='fs-12 m-0 fw-bold color_blue' onClick={() => setStoreVisible(true)}>Change Store</p>
                                        </div>
                                    }
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-2'>
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="Home_Delivery" name="Deliverymethod" value="Home_Delivery" onChange={(e) => { getAddress(e.value); setdeliverystatus(true); Getcartlist(0) }} checked={Deliverymethod === 'Home_Delivery'} />
                                        <label htmlFor="Home_Delivery" className='mx-3'>Home Delivery</label>
                                    </div>
                                    {Deliverymethod == 'Home_Delivery' &&
                                        <div>
                                            <p className='fs-12 m-0 fw-bold color_blue' onClick={() => SetDelivery_Address_visible(true)}>Change Address</p>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="Delivery_Address">
                                {Deliverymethod === 'Store_Pickup' &&
                                    <div>
                                        <div className='mt-3'>
                                            <Row>
                                                <Col sm={4}>
                                                    <div>
                                                        <img alt="Card" className='img-fluid' src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png' height={200} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                                    </div>
                                                </Col>
                                                <Col sm={8}>
                                                    <div>
                                                        <div className='d-flex text-break'>
                                                            <div><img src={Location_Icon} /></div>
                                                            <div className='mx-2'><p>{SelectedStoreData.STORE_NAME}<br /> {SelectedStoreData.ADDRESS_LINE1},{SelectedStoreData.ADDRESS_LINE2},{SelectedStoreData.CITY},{SelectedStoreData.STATE},{SelectedStoreData.COUNTRY}<br />{SelectedStoreData.PINCODE}</p></div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div><img src={Phone_Icon} /></div>
                                                            <div className='mx-2'><p>{SelectedStoreData.CONTACT_NUMBER}</p></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <div><Button className=' btn_p fs-10' onClick={() => showInMapClicked(item)}>Get Directions</Button></div> */}
                                        </div>
                                    </div>
                                }

                                {Deliverymethod === 'Home_Delivery' &&
                                    <div>
                                        <div className='d-flex text-break'>
                                            <div><img src={Location_Icon} /></div>
                                            <div className='mx-2'><p>{PrimaryAddress?.CUSTOMER_NAME} <br />{PrimaryAddress?.ADDRESS_LINE1},{PrimaryAddress?.ADDRESS_LINE2},{PrimaryAddress?.CITY},{PrimaryAddress?.STATE},{PrimaryAddress?.COUNTRY},{PrimaryAddress?.PINCODE}</p></div>
                                        </div>
                                        <div className='d-flex'>
                                            <div><img src={Phone_Icon} /></div>
                                            <div className='mx-2'><p>{PrimaryAddress?.MOBILE_NUMBER}</p></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Card>
                    </div>
                    {/* Delivery Method card end */}

                    {/* Delivery Method Store Pickup mobile number start */}
                    {Deliverymethod === 'Store_Pickup' &&
                     <div className='my-3'>
                     <label htmlFor="Mobile_Number" className="d-block mb-1">Mobile Number</label>
                     <InputText
                         type='text'
                         className='form-control'
                         id='Mobile_Number'
                         placeholder='Enter your Mobile Number'
                         onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                         value={CoustmerMobileNumber}
                         autoFocus
                         minLength={10}
                         maxLength={10}
                         onChange={(e) => setMobileNumber(e.target.value)}
                         aria-describedby="mobile_error"
                     ></InputText>
                     {CoustmerMobileNumber === "" &&
                         <small id="mobile_error" className="p-error block">Mobile is requied.</small>}
                     {CoustmerMobileNumber && (CoustmerMobileNumber?.length > 10 || CoustmerMobileNumber?.length < 10) &&
                         <small id="mobile_error" className="p-error block">Please enter valid Mobile Number.</small>}
                 </div>
                       
                    }
                    {/* Delivery Method Store Pickup mobile number end */}

                    <div className='mb-3'>
                        <div className="col-12 md:col-4">
                            <div className="p-inputgroup">
                                <InputText placeholder="Apply Coupon" className='' />
                                <Button label='Select' onClick={() => { SetCoupon_visible(true) }} className="px-3 p-button-secondary" />
                            </div>
                        </div>
                    </div>
                    {/* Order Details card start */}
                    <div className='Order_Details Card_Order_Process mb-3'>
                        <Card title="Order Details" className='box-shadow-none border rounded border-2'>
                            <div className='d-flex justify-content-between my-1'>
                                <div>Items Count{(CartData.total_count)}</div>
                                <div><span className='mx-1'>{CartData.length}</span></div>
                            </div>
                            <div className='d-flex justify-content-between mb-1'>
                                <div>Price{(CartData.total_count)}</div>
                                <div>&#8377;<span className='mx-1'>{parseFloat(totalamount).toFixed(2)}</span></div>
                            </div>
                            <div className='d-flex justify-content-between mb-1'>
                                <div>Extra Charges</div>
                                <div>(+) &#8377;<span className='mx-1'>{parseFloat(total_extra_charges).toFixed(2)}</span></div>
                            </div>
                            <div className='d-flex justify-content-between mb-1'>
                                <div>Discount Amount</div>
                                <div className='color_red'>(-) &#8377;<span className='mx-1'>{(totalamount - totaldiscountamount).toFixed(2)}</span></div>
                            </div>
                            {Price - TotalPrice > 0 && !Couponstatus &&
                                <div className='d-flex justify-content-between mb-1 color_head fw-bold'>
                                    <div>Coupon Discount ({SelectedCoupon ? SelectedCoupon.COUPON_CODE : ''})</div>
                                    <div>(-) &#8377;<span className='mx-1'>{(Price - TotalPrice).toFixed(2)}</span></div>
                                </div>
                            }
                            <hr className='m-2' />
                            <div className='d-flex justify-content-between mb-2'>
                                <div>Total Price</div>
                                <div>&#8377;<span className='mx-1'>{parseFloat(TotalPrice).toFixed(2)}</span></div>
                            </div>

                        </Card>
                    </div>
                    {/* Order Details card end */}

                    {/* {selected_Coupon_items_data.length > 0 &&
                        <Card>
                            <h6>Coupon Applied Items</h6>
                            {selected_Coupon_items_data.map((item, i) => {
                                return (
                                    <p className='color_head m-0' key={i}>{i + 1}.{item}</p>
                                )
                            })}

                        </Card>
                    } */}


                    <div className='Payment_Mode Card_Order_Process mb-3'>
                        <Card title="Payment Mode" className='pb-3 box-shadow-none border rounded border-2'>
                            <div className="field-radiobutton my-3">
                                <RadioButton inputId="COD" name="Paymentmethod" value="COD" disabled={true} onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')} checked={Paymentmethod === 'COD'} />
                                <label htmlFor="COD" className='mx-3'>Cash On Delivery</label>
                            </div>
                            <div className="field-radiobutton my-3 d-flex justify-content-between">
                                <div>
                                    <RadioButton inputId="ATUM_WALLET" name="Paymentmethod" value="ATUM_WALLET" onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')} checked={Paymentmethod === 'ATUM_WALLET'} />
                                    <label htmlFor="ATUM_WALLET" className='mx-3'>Atum Wallet <b>({parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)})</b></label>
                                </div>
                                {Paymentmethod == 'ATUM_WALLET' && User_Info.TOTAL_WALLET_AMOUNT < Price ?
                                    <div>
                                        <span className='' style={{ color: 'red' }}><b>{parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)}</b> Please upgrade the amount from <Link id="RouterNavLink" to='/wallet'>Wallet</Link></span>
                                    </div> : ''
                                }
                            </div>
                            <div className="field-radiobutton my-3">
                                <RadioButton inputId="ONLINE" name="Paymentmethod" value="ONLINE" onChange={(e) => setpaymentmethod(e.value) && setpayment_status('SUCCESS')} checked={Paymentmethod === 'ONLINE'} />
                                <label htmlFor="ONLINE" className='mx-3'>Pay Now</label>
                            </div>
                            <div>
                                <div className='primary_btn d-flex mt-3 justify-content-center'>
                                    <Button className="btn_p fs-12 px-4" onClick={() => Place_Order()} disabled={(Deliverymethod === 'Store_Pickup' && (CoustmerMobileNumber?.length < 10 || !CoustmerMobileNumber)) || (Deliverymethod === 'Store_Pickup' && invalidproduct) || (Deliverymethod === 'Home_Delivery' && !errorPrimaryAddress) || noproductserror || (Paymentmethod == 'ATUM_WALLET' && parseInt(User_Info.TOTAL_WALLET_AMOUNT) < parseInt(TotalPrice))} >Proceed to Pay</Button>
                                </div>
                                {Deliverymethod === 'Store_Pickup' && invalidproduct &&
                                    <div className='col-sm-12 pb-1 color_red'>
                                        <small>Please Remove Products which are not Available.</small><br />
                                        <small>Select Home Delivery to get whole Products.</small>
                                    </div>
                                }
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <Dialog header="Add New Address" style={{ width: '50vw' }} visible={Dailogflag} onHide={() => setDailogflag(false)}>
                <div>
                    <Form className='' onSubmit={AddressForm.handleSubmit}>
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="field mb-3">
                                    <label htmlFor="CUSTOMER_NAME" className="d-block mb-1">Customer Name <sup className='color_pink'>*</sup></label>
                                    <InputText
                                        type='text'
                                        id='CUSTOMER_NAME'
                                        placeholder='Enter Your Customer Name'
                                        value={AddressForm.values.CUSTOMER_NAME}
                                        onChange={AddressForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('CUSTOMER_NAME') }))}
                                    ></InputText>
                                    <small>{getFormErrorMessage('CUSTOMER_NAME')}</small>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="field mb-3">
                                    <label htmlFor="MOBILE_NUMBER" className="d-block mb-1">Mobile Number <sup className='color_pink'>*</sup></label>
                                    <InputText
                                        type='text'
                                        id='MOBILE_NUMBER'
                                        // className='form-control'
                                        placeholder='Enter Your Mobile Number'
                                        value={AddressForm.values.MOBILE_NUMBER}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={AddressForm.handleChange}
                                        className={(classNames({ 'p-invalid': isFormFieldValid('MOBILE_NUMBER') }))}
                                    ></InputText>
                                    <small>{getFormErrorMessage('MOBILE_NUMBER')}</small>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="field mb-3">
                                    <label htmlFor="COUNTRY" className="d-block mb-1">Country <sup className='color_pink'>*</sup></label>
                                    <InputText
                                        type='text'
                                        id='COUNTRY'
                                        className={(classNames({ 'p-invalid': isFormFieldValid('COUNTRY') }))}
                                        placeholder='Enter Your Country'
                                        value={AddressForm.values.COUNTRY}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={AddressForm.handleChange}
                                    ></InputText>
                                    <small>{getFormErrorMessage('COUNTRY')}</small>
                                </div>
                            </Col>

                            <Col sm={12} md={6}>
                                <div className="field mb-3">
                                    <label htmlFor="CITY" className="d-block mb-1">City <sup className='color_pink'>*</sup></label>
                                    <InputText
                                        type='text'
                                        id='CITY'
                                        className={(classNames({ 'p-invalid': isFormFieldValid('CITY') }))}
                                        placeholder='Enter your City'
                                        value={AddressForm.values.CITY}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={AddressForm.handleChange}
                                    ></InputText>
                                    <small>{getFormErrorMessage('CITY')}</small>
                                </div>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="field mb-3">
                                    <label htmlFor="STATE" className="d-block mb-1">State <sup className='color_pink'>*</sup></label>
                                    <InputText
                                        type='text'
                                        id='STATE'
                                        className={(classNames({ 'p-invalid': isFormFieldValid('STATE') }))}
                                        placeholder='Enter your State'
                                        value={AddressForm.values.STATE}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={AddressForm.handleChange}
                                    ></InputText>
                                    <small>{getFormErrorMessage('STATE')}</small>
                                </div>
                            </Col>

                            <Col sm={12} md={6}>
                                <div className="field mb-3">
                                    <label htmlFor="PINCODE" className="d-block mb-1">Pincode <sup className='color_pink'>*</sup></label>
                                    <InputText
                                        type='text'
                                        id='PINCODE'
                                        className={(classNames({ 'p-invalid': isFormFieldValid('PINCODE') }))}
                                        placeholder='Enter Your PINCODE'
                                        value={AddressForm.values.PINCODE}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={AddressForm.handleChange}
                                    ></InputText>
                                    <small>{getFormErrorMessage('PINCODE')}</small>
                                </div>
                            </Col>
                            <Col sm={12} md={12}>
                                <div className="field mb-3">
                                    <label htmlFor="ADDRESS_LINE1" className="d-block mb-1">Full Address <sup className='color_pink'>*</sup></label>
                                    <InputTextarea rows={2} cols={30}
                                        type='text'
                                        className={(classNames({ 'p-invalid': isFormFieldValid('ADDRESS_LINE1') }))}
                                        id='ADDRESS_LINE1'
                                        placeholder='Enter your Address Line 1'
                                        value={AddressForm.values.ADDRESS_LINE1}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={AddressForm.handleChange}
                                    ></InputTextarea>
                                    <small>{getFormErrorMessage('ADDRESS_LINE1')}</small>
                                </div>
                            </Col>
                            <Col sm={12} md={12}>
                                <div className="field mb-3">
                                    <label htmlFor="ADDRESS_LINE2" className="d-block mb-1">Landmark/ Area <sup className='color_pink'>*</sup></label>
                                    <InputTextarea rows={2} cols={30}
                                        type='text'
                                        id='ADDRESS_LINE2'
                                        className={(classNames({ 'p-invalid': isFormFieldValid('ADDRESS_LINE2') }))}
                                        placeholder='Enter your Address Line 2'
                                        value={AddressForm.values.ADDRESS_LINE2}
                                        // onChange={(e) => setFirstName(e.target.value)}
                                        onChange={AddressForm.handleChange}
                                    ></InputTextarea>
                                    <small>{getFormErrorMessage('ADDRESS_LINE2')}</small>
                                </div>
                            </Col>
                            <Col sm={6} md={6}>
                                <div className="field-checkbox">
                                    <Checkbox inputId="IS_PRIMARY" name="IS_PRIMARY" checked={AddressForm.values.IS_PRIMARY} onChange={AddressForm.handleChange} disabled={Multipleaddress.length == 0} />
                                    <label htmlFor="IS_PRIMARY" className='mx-3' >PRIMARY</label>
                                </div>
                            </Col>
                            <div className='primary_btn d-flex justify-content-center'>
                                <Button label='Cancel' type='button' onClick={() => setDailogflag(false)} className='mx-3 px-4 p-button-text p-button-secondary' />
                                {!editaddressflag ?
                                    <Button type='submit' className='px-4 rounded' variant="success">
                                        Procced </Button> :
                                    <Button type='submit' className='px-4 rounded' variant="success">
                                        Edit </Button>
                                }
                            </div>
                        </Row>
                    </Form>
                </div>
            </Dialog>
            <Dialog header="Coupon Alert" style={{ width: '50vw' }} visible={Dailogcouponflag} onHide={() => setDailogcouponflag(false)}>
                <div>
                    <p className='color_red text-center'>{Coupon_Alert}</p>
                    {/* <p>Please Try other Coupons</p> */}
                </div>
            </Dialog>
            <Dialog header="Coupons" style={{ width: '60vw' }} visible={couponsFlag} onHide={() => setcouponsFlag(false)}>
                <div>
                    <Row className=''>
                        {CouponsData.map((coupon, index) => {
                            const Coupon_Type_Display = (
                                <span>
                                    {coupon.COUPON_VALUE_TYPE == 'AMOUNT' ?
                                        <span>&#8377;{coupon.COUPON_VALUE}</span> :
                                        <span>{coupon.COUPON_VALUE} %</span>
                                    }
                                </span>
                            )
                            const ReadMore = ({ children, index }) => {
                                const text = children;
                                const [isReadMore, setIsReadMore] = useState(true);
                                const toggleReadMore = () => {
                                    setIsReadMore(!isReadMore);
                                };
                                return (
                                    <small className="Coupon_text" key={index}>
                                        {isReadMore ? text.slice(0, 20) : text}
                                        <span onClick={toggleReadMore} className="read-or-hide">
                                            {isReadMore ? <small>{text.length > 20 ? "...read more" : ''}</small> : <small>show less</small>}
                                        </span>
                                    </small>
                                );
                            };
                            return (
                                <Col md={6} className="my-2" key={index}>
                                    {/* <div> */}
                                    <Card className={(new Date() >= new Date(coupon.END_DATE) || new Date() <= new Date(coupon.START_DATE) || coupon.COUPON_USED == 1 || coupon.MIN_ORDER_VALUE >= (parseFloat(totalamount) - parseFloat(total_extra_charges)) ? 'card_disable' : '')}>
                                        <h6>{Coupon_Type_Display} Off upto &#8377; {coupon.MAX_DISCOUNT}</h6>
                                        <p className='m-0'>{coupon.COUPON_CODE}</p>
                                        <div className='fs-10 d-flex justify-content-between align-items-center'>
                                            <small>Min Order Value {coupon.MIN_ORDER_VALUE}</small>
                                        </div>
                                        <p className='m-0'>
                                            Description : <ReadMore>{coupon.DESCRIPTION}</ReadMore>
                                        </p>
                                        <p>
                                            Term & Conditions : <ReadMore>{coupon.TERMS_AND_CONDITIONS}</ReadMore>
                                        </p>
                                        <hr style={{ borderTop: "1px dotted #302b2b" }} className="m-2" />
                                        <div className='fs-10 d-flex justify-content-between align-items-center'>
                                            <div>
                                                <h6 className='m-0'>{coupon.COUPON_TYPE}</h6>
                                                {/* <small className='fs-10'>This coupon is for referral bouns</small> */}
                                                <p className='m-0 fs-12'>Valid Till : {DateFormat(coupon.END_DATE)} </p>
                                            </div>
                                            <div className='primary_btn'>
                                                {!Couponstatus && SelectedCoupon.ID == coupon.ID ? <Button className="fs-10 px-4" onClick={() => CouponremoveCalculation(coupon)} >Remove</Button> //disabled={new Date() > new Date(coupon.END_DATE) && coupon.MIN_ORDER_VALUE > totaldiscountamount}
                                                    : <Button className="fs-10 px-4" onClick={() => CouponCalculation(coupon)} disabled={new Date() >= new Date(coupon.END_DATE) || new Date() <= new Date(coupon.START_DATE) || coupon.COUPON_USED == 1 || coupon.MIN_ORDER_VALUE > totaldiscountamount} >Apply</Button>
                                                }
                                            </div>
                                        </div>
                                    </Card>
                                    {/* </div> */}
                                </Col>
                            )
                        })
                        }
                        {CouponsData.length <= 0 && <h4 className='text-error'>No Coupons Available</h4>}
                    </Row>
                </div>
            </Dialog>
        </section>
    )
}
export default Submitorder;