import React, { useState, useEffect } from 'react';
//import { Carousel } from 'primereact/carousel';
import { Galleria } from 'primereact/galleria';
//import ReactDOM from 'react-dom';
//import image1 from '../../Assets/2.jpeg';
import { GLOBAL_DOMAIN, IMG_GLOBAL } from '../../Config/Config';

const ProductGalleri_Carousel = (props) => {
    const images = props.GetImg_Array

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 2
        }
    ];

    useEffect(() => {
        console.log(images)
        // images == props.GetImg_Array;
        // galleriaService.getImages().then(data => setImages(data));
    }, []);

    const itemTemplate = (item) => {
        return (
            item.title == 'Vedio' ? <>
                <video height="320px" style={{width:'100%'}} >
                    <source src={IMG_GLOBAL + item.itemImageSrc} type="video/mp4" />
                </video>
            </> :
                <img src={IMG_GLOBAL + item.itemImageSrc} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ width: '100%', height: '320px', display: 'block' }} />
        )
    }

    const thumbnailTemplate = (item) => {
        return (
            item.title == 'Vedio' ? <>
                <video style={{width:'100%'}} >
                    <source src={IMG_GLOBAL + item.itemImageSrc} type="video/mp4" />
                </video>
            </> :
                <img src={IMG_GLOBAL + item.itemImageSrc} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ display: 'block' }} />
        )
    }

    return (
        <div>
            {/* <div className="card"> */}
            {/* <h5>Item Navigators and Thumbnails</h5> */}
            <Galleria value={images} responsiveOptions={responsiveOptions} numVisible={5} circular style={{ maxWidth: '640px' }}
                showItemNavigators={images.length > 1 ? true : false} item={itemTemplate} thumbnail={thumbnailTemplate} />
            {/* </div> */}
        </div>
    );
}

export default ProductGalleri_Carousel;