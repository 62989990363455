import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import axios from 'axios';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers } from '../../../Config/Config';
import confirm_Icon from '../../../Assets/icons/Confirm_icon.svg';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

const OrderConfirmation = (props) => {

    const OrderData_ID = props.location.state.Order_Data;
    const navigate = useHistory()
    const [OrderData, setOrderData] = useState('');
    console.log(OrderData_ID);

    const getOrderView = () => {
        console.log(props)
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/orderItems?type=list&limit=100&orderTrackerId=' + OrderData_ID.data.data.ORDER_TRACKER_ID,
        }).then((res) => {
            console.log(res)
            setOrderData(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        // getUserInfo();
        getOrderView();
    }, []);

    return (
        <div className='container'>
            <div className='d-flex justify-content-center'>
                <Row>
                    <Col sm={12} md={3}>
                        <div>
                            <img src={confirm_Icon} />
                        </div>
                    </Col>
                    <Col sm={12} md={9}>
                        <div className='text-center mx-3' style={{ width: '400px' }}>

                            <h4 className='color_head'>Your order confirmed</h4>
                            <small className='text-grey'>Thank you for your order! We've received it and will start preparing it for shipment. You'll receive updates on your delivery soon. Happy shopping!</small>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='my-5'>
                <Row>
                    <Col sm={12} md={6}>
                        {OrderData && OrderData.rows.map((items, index) => {
                            return (
                                <div key={index} className=' mb-3 pb-3 border-bottom border-last'>
                                    <Row items>
                                        <Col sm={4} md={4}>
                                            <img className="d-inline-block border rounded p-1 align-top" width='100%' height={100} src={IMG_GLOBAL + items.IMAGE_PATH} />
                                        </Col>
                                        <Col sm={8} md={8}>
                                            <div>
                                                <p>{items.PRODUCT_NAME}</p>
                                                <div className='d-flex position-relative'>
                                                    <div>
                                                        {/* <p>Qty: {items.QUANTITY}</p> */}
                                                        {items.IS_COLOR_VARIANT == 1 &&
                                                            <div>
                                                                <div className='Attribute_color_card text-center align-items-center d-flex'>
                                                                    <p className='m-0 fs-14'>Color:</p>
                                                                    <p className='Attribute_color d-inline-flex' style={{ height: '20px', width: '20px', backgroundColor: items.PRODUCT_COLOR }}></p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='position-absolute end-0 text-end lh-sm'>
                                                        <p className='m-0'>&#8377; {parseFloat(items.TOTAL_PRICE).toFixed(2)}</p>
                                                        {items.QUANTITY > 1 &&
                                                            <small className='text-grey fs-12'>({items.QUANTITY} * {parseFloat(items.PRICE).toFixed(2)})</small>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                        {/* <div class="border border-last"></div> */}
                                </div>
                            )
                        })

                        }
                    </Col>
                    <Col sm={12} md={6}>
                        <div class="confrim_order_details">
                            <div className='price-details-main'>
                                <h6 class="text-dark font-weight-bold ls-1">Order Details</h6>
                                <div class="border my-2"></div>
                                <div class="price-details-info">
                                    <div class="d-flex justify-content-between align-items-center mb-2 fs-14">
                                        <div class="text-capitalize">
                                            <span>items Count</span>
                                        </div>
                                        <div>
                                            <span>{OrderData.total_count}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2 fs-14">
                                        <div class="text-capitalize">
                                            <span>Cart total</span>
                                        </div>
                                        <div>
                                            <span>₹ {OrderData.TOTAL_AMOUNT}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2 fs-14">
                                        <div>
                                            <span>Coupon Discount</span>
                                        </div>
                                        <div>
                                            <span>₹ 0.00</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2 fs-14">
                                        <div>
                                            <span class="font-weight-bold">Extra Charges</span>
                                        </div>
                                        <div>
                                            <span class="font-weight-bold">₹ {OrderData.TOTAL_EXTRA_CHARGES}</span>
                                        </div>
                                    </div>
                                    <div class="border"></div>
                                    <div class="d-flex justify-content-between align-items-center mb-2 fs-14">
                                        <div class="py-2">
                                            <span class="font-weight-bold">Total</span>
                                        </div>
                                        <div class="py-2">
                                            <span class="font-weight-bold">₹ {OrderData.ORDER_TOTAL_AMOUNT}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <div className='d-flex justify-content-center my-5'>
                        <div className='mx-2'>
                        <Button label="Track Order" onClick={() => navigate.push('/orderlist')} className="p-button-raised rounded p-button-secondary" />
                        </div>
                        <div className='primary_btn mx-2'>
                        <Button icon="pi pi-arrow-right" onClick={() => navigate.push('/home')}iconPos='right' label="Continue Shopping" className=" btn_p p-button-raised" />
                        </div>
                </div>
            </div>
        </div>
    )
}

export default OrderConfirmation