import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { GLOBAL_DOMAIN, Api_Headers } from '../../../Config/Config';
import axios from 'axios';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import '../../Submitorder/Submitorder.css';
const DeliveryAddress = () => {

//    const [userAddresses, setUserAddresses] = useState([]);
    const [PrimaryAddress, setPrimaryAddress] = useState({});
    const [Multipleaddress, setMultipleaddress] = useState([]);
    const [multipleaddressflag, setmultiaddressflag] = useState(false);
    const [editaddressflag, seteditaddressflag] = useState(false);
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const [Dailogflag, setDailogflag] = useState(false);
    const [EditID, setEditID] = useState();
    useEffect(() => {
        // getUserInfo();
        // getUserAddress();
        getAddress();
    }, []);

    const getAddress = () => {
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        // setdeliverymethod(address);
        axios({
            method: 'GET',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses?type=list&limit=500&userId=' + user_data.data.userData.ID
        }).then((res) => {
            let objs = null;
            console.log(res.data.rows)
            setMultipleaddress(res.data.rows);
            if (res.data.rows.length > 0) {
                for (let i = 0; i < res.data.rows.length; i++) {
                    if (res.data.rows[i].IS_PRIMARY == 1) {
                        objs = res.data.rows[i];
                        setPrimaryAddress(objs);
                        console.log(PrimaryAddress);
                    }
                };
            } else {
                setPrimaryAddress({});
            }
            console.log('selected Address: ', objs);
        }).catch((err) => {
            console.log(err);
        });
    }


    const seteditaddressess = (editaddress) => {
        console.log(editaddress)
        setDailogflag(true);
        seteditaddressflag(true);
        setEditID(editaddress.ID);
        AddressForm.setFieldValue("CUSTOMER_NAME", editaddress.CUSTOMER_NAME)
        AddressForm.setFieldValue("MOBILE_NUMBER", editaddress.MOBILE_NUMBER)
        AddressForm.setFieldValue("ADDRESS_LINE1", editaddress.ADDRESS_LINE1)
        AddressForm.setFieldValue("ADDRESS_LINE2", editaddress.ADDRESS_LINE2)
        AddressForm.setFieldValue("CITY", editaddress.CITY)
        AddressForm.setFieldValue("STATE", editaddress.STATE)
        AddressForm.setFieldValue("COUNTRY", editaddress.COUNTRY)
        AddressForm.setFieldValue("PINCODE", editaddress.PINCODE)
        AddressForm.setFieldValue("IS_PRIMARY", editaddress.IS_PRIMARY == 1 ? true : false)
    }

    const AddressForm = useFormik({

        initialValues: {
            CUSTOMER_NAME: "",
            MOBILE_NUMBER: "",
            ADDRESS_LINE1: "",
            ADDRESS_LINE2: "",
            CITY: "",
            STATE: "",
            COUNTRY: "",
            PINCODE: "",
            IS_PRIMARY: true
        },
        validate: (data) => {
            let errors = {};
            if (!data.CUSTOMER_NAME) {
                errors.CUSTOMER_NAME = 'CUSTOMER_NAME is required.';
            }
            if (!data.MOBILE_NUMBER) {
                errors.MOBILE_NUMBER = 'MOBILE_NUMBER is required.';
            } else if (!/^[6-9]\d{9}$/i.test(data.MOBILE_NUMBER)) {
                errors.MOBILE_NUMBER = 'Invalid Phone Number';
            }
            if (!data.ADDRESS_LINE1) {
                errors.ADDRESS_LINE1 = 'ADDRESS_LINE1 is required.';
            }
            if (!data.ADDRESS_LINE2) {
                errors.ADDRESS_LINE2 = 'ADDRESS_LINE2 is required.';
            }
            if (!data.CITY) {
                errors.CITY = 'CITY is required.';
            }
            if (!data.STATE) {
                errors.STATE = 'STATE is required.';
            }
            if (!data.COUNTRY) {
                errors.COUNTRY = 'COUNTRY is required.';
            }
            if (!data.PINCODE) {
                errors.PINCODE = 'PINCODE is required.';
            } else if (!/^[1-9][0-9]{5}$/i.test(data.PINCODE)) {
                errors.PINCODE = 'Invalid Pincode';
            }
            return errors;
        },
        onSubmit: (data) => {
            // setFormData(data);
            // setPrimaryAddress(data);
            if (editaddressflag) {
                editaddresses(data)
            } else {
                formaddress(data);
            }
            console.log('From Data', data)
            resetaddress();
        }
    })

    const resetaddress = () => {
        AddressForm.resetForm();
    }

    const isFormFieldValid = (name) => !!(AddressForm.touched[name] && AddressForm.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{AddressForm.errors[name]}</small>;
    };

    const formaddress = (address) => {
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        console.log('Form', address);
        let body = Object.assign(address, { USER_ID: user_data.data.userData.ID })
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses',
            data: body
        }).then((res) => {
            console.log(res)
            setDailogflag(false)
            getAddress();
        }).catch((err) => {
            console.log(err);
        });
    }

    const editaddresses = (editdata) => {
        console.log('edit address', editdata);
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));

        let body = Object.assign(editdata, { USER_ID: user_data.data.userData.ID })
        axios({
            method: 'PUT',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses/' + EditID,
            data: body
        }).then((res) => {
            console.log(res)
            setDailogflag(false)
            getAddress()
        }).catch((err) => {
            console.log(err);
        });
    }

    const deleteaddress = (deletedata) => {
        axios({
            method: 'DELETE',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses/' + deletedata.ID,
            data: {}
        }).then((res) => {
            console.log(res);
            if (deletedata.ID == PrimaryAddress.ID) {
                setPrimaryAddress({})
            }
            getAddress();
        }).catch((err) => {
            console.log(err);
        });

    }
    
    const footer = (
        <span>
            <Button label="Save" icon="pi pi-check" />
            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary ml-2" />
        </span>
    );


    return (
        <section>
            <div>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h4 className=''>Delivery Address</h4>
                    <Button className=" btn_p fs-10" onClick={() => setDailogflag(true) || seteditaddressflag(false) || resetaddress()} >Add New</Button>
                </div>
                {/* <Row>

                {userAddresses.map(item => {
                    return (
                        <Col xs={6} md={4} className="mb-3">
                            <Card className='text-break overflow-auto' style={{height:'200px'}}>
                                <h6 className="text-truncate">{item.CUSTOMER_NAME}</h6>
                                <p className='text-justify'>
                                    {item.ADDRESS_LINE1},
                                    {item.ADDRESS_LINE2},
                                    {item.CITY},
                                    {item.STATE},
                                    {item.COUNTRY},
                                    {item.PINCODE},
                                </p>
                                <p><b>PH :</b> {item.MOBILE_NUMBER}</p>
                            </Card>
                        </Col>
                    )
                }
                )}
                    </Row> */}
                <Row>
                    {/* {Multipleaddress ? <h6 className='mb-3'>Yours Address</h6> : <h6 className='mb-3'>No Address Found Please add the Address</h6>} */}
                    {Multipleaddress.map(item => {
                        return (
                            <Col md={4} className='mb-3'>
                                <Card className={'text-break overflow-auto' + (item.ID == PrimaryAddress.ID ? ' card_active' : '')} style={{ height: '250px' }} onClick={() => setmultiaddressflag(false)}>
                                    <div className='d-flex justify-content-between'>
                                        <div><h6>{item.CUSTOMER_NAME}</h6></div>
                                        <div>
                                        <i className="pi pi-pencil color_head mx-2" onClick={() =>  seteditaddressess(item)}></i>
                                        <i className="pi pi-trash color_head mx-2" onClick={() => deleteaddress(item)}></i>
                                        </div>
                                    </div>
                                    <p>{item.ADDRESS_LINE1},{item.ADDRESS_LINE2},{item.CITY},{item.STATE},{item.COUNTRY}-{item.PINCODE}</p>
                                    <p>Mobile Number : {item.MOBILE_NUMBER}</p>
                                    <div className="field-checkbox">
                            <Checkbox inputId="IS_PRIMARY" name="IS_PRIMARY" disabled={true} checked={item.ID == PrimaryAddress.ID} onChange={()=> setPrimaryAddress(item) }/>
                            <label htmlFor="IS_PRIMARY" className='mx-3' >PRIMARY</label>
                        </div>
                                </Card>
                            </Col>
                        )
                    })
                    }
                </Row>
                {Multipleaddress && Multipleaddress.length == 0 &&
                    <div>No Delivery Address Found</div>
                }
            </div>
            <Dialog header="Add New Address" style={{ width: '50vw' }} visible={Dailogflag} onHide={() => setDailogflag(false)}>
                <div>
                    <Form className='' onSubmit={AddressForm.handleSubmit}>
                        <div className="field mb-3">
                            <label htmlFor="CUSTOMER_NAME" className="d-block mb-1">Customer Name</label>
                            <InputText
                                type='text'
                                id='CUSTOMER_NAME'
                                placeholder='Enter Your Customer Name'
                                value={AddressForm.values.CUSTOMER_NAME}
                                onChange={AddressForm.handleChange}
                                className={(classNames({ 'p-invalid': isFormFieldValid('CUSTOMER_NAME') }))}
                            ></InputText>
                            <small>{getFormErrorMessage('CUSTOMER_NAME')}</small>
                        </div>
                        <div className="field mb-3">
                            <label htmlFor="MOBILE_NUMBER" className="d-block mb-1">Mobile Number</label>
                            <InputText
                                type='text'
                                id='MOBILE_NUMBER'
                                // className='form-control'
                                placeholder='Enter Your Mobile Number'
                                value={AddressForm.values.MOBILE_NUMBER}
                                // onChange={(e) => setFirstName(e.target.value)}
                                onChange={AddressForm.handleChange}
                                className={(classNames({ 'p-invalid': isFormFieldValid('MOBILE_NUMBER') }))}
                            ></InputText>
                            <small>{getFormErrorMessage('MOBILE_NUMBER')}</small>
                        </div>

                        <div className="field mb-3">
                            <label htmlFor="ADDRESS_LINE1" className="d-block mb-1">Address 1</label>
                            <InputText
                                type='text'
                                className={(classNames({ 'p-invalid': isFormFieldValid('ADDRESS_LINE1') }))}
                                id='ADDRESS_LINE1'
                                placeholder='Enter your Address Line 1'
                                value={AddressForm.values.ADDRESS_LINE1}
                                // onChange={(e) => setFirstName(e.target.value)}
                                onChange={AddressForm.handleChange}
                            ></InputText>
                            <small>{getFormErrorMessage('ADDRESS_LINE1')}</small>
                        </div>

                        <div className="field mb-3">
                            <label htmlFor="ADDRESS_LINE2" className="d-block mb-1">Address 2</label>
                            <InputText
                                type='text'
                                id='ADDRESS_LINE2'
                                className={(classNames({ 'p-invalid': isFormFieldValid('ADDRESS_LINE2') }))}
                                placeholder='Enter your Address Line 2'
                                value={AddressForm.values.ADDRESS_LINE2}
                                // onChange={(e) => setFirstName(e.target.value)}
                                onChange={AddressForm.handleChange}
                            ></InputText>
                            <small>{getFormErrorMessage('ADDRESS_LINE2')}</small>
                        </div>

                        <div className="field mb-3">
                            <label htmlFor="CITY" className="d-block mb-1">City</label>
                            <InputText
                                type='text'
                                id='CITY'
                                className={(classNames({ 'p-invalid': isFormFieldValid('CITY') }))}
                                placeholder='Enter your City'
                                value={AddressForm.values.CITY}
                                // onChange={(e) => setFirstName(e.target.value)}
                                onChange={AddressForm.handleChange}
                            ></InputText>
                            <small>{getFormErrorMessage('CITY')}</small>
                        </div>

                        <div className="field mb-3">
                            <label htmlFor="STATE" className="d-block mb-1">State</label>
                            <InputText
                                type='text'
                                id='STATE'
                                className={(classNames({ 'p-invalid': isFormFieldValid('STATE') }))}
                                placeholder='Enter your State'
                                value={AddressForm.values.STATE}
                                // onChange={(e) => setFirstName(e.target.value)}
                                onChange={AddressForm.handleChange}
                            ></InputText>
                            <small>{getFormErrorMessage('STATE')}</small>
                        </div>

                        <div className="field mb-3">
                            <label htmlFor="COUNTRY" className="d-block mb-1">Country</label>
                            <InputText
                                type='text'
                                id='COUNTRY'
                                className={(classNames({ 'p-invalid': isFormFieldValid('COUNTRY') }))}
                                placeholder='Enter Your Country'
                                value={AddressForm.values.COUNTRY}
                                // onChange={(e) => setFirstName(e.target.value)}
                                onChange={AddressForm.handleChange}
                            ></InputText>
                            <small>{getFormErrorMessage('COUNTRY')}</small>
                        </div>

                        <div className="field mb-3">
                            <label htmlFor="PINCODE" className="d-block mb-1">Pincode</label>
                            <InputText
                                type='text'
                                id='PINCODE'
                                className={(classNames({ 'p-invalid': isFormFieldValid('PINCODE') }))}
                                placeholder='Enter Your PINCODE'
                                value={AddressForm.values.PINCODE}
                                // onChange={(e) => setFirstName(e.target.value)}
                                onChange={AddressForm.handleChange}
                            ></InputText>
                            <small>{getFormErrorMessage('PINCODE')}</small>
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="IS_PRIMARY" name="IS_PRIMARY" checked={AddressForm.values.IS_PRIMARY} onChange={AddressForm.handleChange} disabled={Multipleaddress.length == 0} />
                            <label htmlFor="IS_PRIMARY" className='mx-3' >PRIMARY</label>
                        </div>
                        <div className='mb-3' style={{ display: 'flex', justifyContent: 'end' }}>
                            {!editaddressflag ?
                                <Button type='submit' className='px-5 btn_p' variant="success" style={{ borderRadius: '25px' }}>
                                    Submit </Button> :
                                <Button type='submit' className='px-5 btn_p' variant="success" style={{ borderRadius: '25px' }}>
                                    Edit </Button>
                            }
                        </div>
                    </Form>
                </div>
            </Dialog>
        </section>
    )
}
export default DeliveryAddress