import React, { useEffect, useState } from 'react';
import { Card } from "primereact/card"
import axios from 'axios';
import { Form, Button, Row, Col, Carousel, Container } from "react-bootstrap";
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers, Payment_Gateway } from '../../../Config/Config';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
//import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';

const PrebookingOrder = (props) => {

    const history = useHistory();
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const [Paymentmethod, setpaymentmethod] = useState('ATUM_WALLET');
//    const [TotalPrice, setTotalPrice] = useState(1000);
    const [paymentStatus, setpayment_status] = useState('SUCCESS');
    // const [Product_Data, setCartData] = useState({});
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    // const CartData = JSON.parse(window.localStorage.getItem('prebooking_data'));
    const [User_Info, setuserinfo] = useState({});
    const [AAttributeData, setAttributeData] = useState({});
    const { Attribute_Data } = location.state;
    // const {Product_Data} = location.state;
    // const {Attribute_price_data} = location.state;
    useEffect(() => {
        // setCartData(window.localStorage.getItem('prebooking_data'))
        console.log(Attribute_Data);
        // console.log(Product_Data);
        getUserInfo();
        if (Attribute_Data == '') {
            setAttributeData({ "ID": 0 });
        } else {
            setAttributeData(Attribute_Data)
        }
        console.log(Attribute_Data);
    }, [])

    const getUserInfo = () => {
        // const user_data = JSON.parse(window.localStorage.getItem('User_data'));
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
        }).then((res) => {
            setuserinfo(res.data);
            console.log("Userinfo data", res)
        }).catch((err) => {
            console.log(err);
        });
    }

    const AddressForm = useFormik({

        initialValues: {
            CUSTOMER_NAME: "",
            MOBILE_NUMBER: "",
            ADDRESS_LINE1: "",
            ADDRESS_LINE2: "",
            CITY: "",
            STATE: "",
            COUNTRY: "",
            PINCODE: "",
            IS_PRIMARY: true
        },
        validate: (data) => {
            let errors = {};
            if (!data.CUSTOMER_NAME) {
                errors.CUSTOMER_NAME = 'CUSTOMER_NAME is required.';
            }
            if (!data.MOBILE_NUMBER) {
                errors.MOBILE_NUMBER = 'MOBILE_NUMBER is required.';
            } else if (!/^[6-9]\d{9}$/i.test(data.MOBILE_NUMBER)) {
                errors.MOBILE_NUMBER = 'Invalid Phone Number';
            }
            if (!data.ADDRESS_LINE1) {
                errors.ADDRESS_LINE1 = 'ADDRESS_LINE1 is required.';
            }
            if (!data.ADDRESS_LINE2) {
                errors.ADDRESS_LINE2 = 'ADDRESS_LINE2 is required.';
            }
            if (!data.CITY) {
                errors.CITY = 'CITY is required.';
            }
            if (!data.STATE) {
                errors.STATE = 'STATE is required.';
            }
            if (!data.COUNTRY) {
                errors.COUNTRY = 'COUNTRY is required.';
            }
            if (!data.PINCODE) {
                errors.PINCODE = 'PINCODE is required.';
            } else if (!/^[1-9][0-9]{5}$/i.test(data.PINCODE)) {
                errors.PINCODE = 'Invalid Pincode';
            }
            return errors;
        },
        onSubmit: (data) => {
            console.log(data)
            setFormData(data);
            PlaceOrder(data);
            // resetaddress();
        }
    })

    const resetaddress = () => {
        AddressForm.resetForm();
    }

    const isFormFieldValid = (name) => !!(AddressForm.touched[name] && AddressForm.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{AddressForm.errors[name]}</small>;
    };

    const PlaceOrder = (predata) => {
        console.log("prebooking data", Attribute_Data)
        console.log("PlaceOder", formData);
        const Order_Item = {
            PRODUCT_ID: Attribute_Data.ID,
            QUANTITY: 1,
            USER_ID: user_data.data.userData.ID,
            DISCOUNT_PRICE: parseFloat(Attribute_Data.DISCOUNT_PRICE),
            ORDER_TOTAL_AMOUNT: parseFloat(Attribute_Data.ORDER_TOTAL_AMOUNT),
            PRICE: parseFloat(Attribute_Data.PRICE),
            PRODUCTS_QUANTITY: Attribute_Data.QUANTITY
        };
        const Shipping_Address = {
            MOBILE_NUMBER: predata.MOBILE_NUMBER,
            ADDRESS_LINE1: predata.ADDRESS_LINE1,
            ADDRESS_LINE2: predata.ADDRESS_LINE2,
            STATE: predata.STATE,
            CITY: predata.CITY,
            COUNTRY: predata.COUNTRY,
            PINCODE: predata.PINCODE,
        }
        let OrderData = {
            orderItems: Order_Item,
            shippingAddress: Shipping_Address,
            DELIVERY_TYPE: "CUSTOMER_ADDRESS",
            // PAYMENT_STATUS: paymentStatus,
            PAYMENT_MODE: Paymentmethod,
            ORDER_TRACKER_ID: 0,
            ORDER_AMOUNT: parseFloat(Attribute_Data.ORDER_TOTAL_AMOUNT),
            TRANSACTION_TOKEN: "",
            TRANSACTION_RESPONSE_TOKEN: "",
            STATUS: "PREBOOK",
            PREBOOK_AMOUNT: Attribute_Data.attributesInfo.PREBOOK_AMOUNT[0].ATTRIBUTE_VALUE,
            DELIVERY_CHARGES: 0,
            USER_ID: user_data.data.userData.ID,
            ACTION: "PREBOOK",
            PAYMENT_STATUS: "SUCCESS",
            PLATFORM: "WEBSITE",
            // STORE_ID : SelectedstorepickupID
        };

        let body = null

        if (Paymentmethod == 'COD') {
            OrderData.PAYMENT_STATUS = "PENDING";
            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/userorders',
                data: OrderData,
            }).then((res) => {
                console.log('order placed', res.data);
                history.push('/orderlist');
                resetaddress()
            }).catch((err) => {
                console.log(err);
            });
        }

        else if (Paymentmethod == 'ONLINE') {
            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/paymentgateway/generateWebToken',
                data: {
                    'ORDER_TOTAL_AMOUNT': `${Attribute_Data.attributesInfo.PREBOOK_AMOUNT[0].ATTRIBUTE_VALUE}`,
                    'USER_ID': user_data.data.userData.ID,
                }
            }).then((res) => {
                console.log('paytm started: ', res.data.data, JSON.parse(res.data.paytmchecksum));
                const checksumInfo = JSON.parse(res.data.paytmchecksum);
                var config = {
                    "root": "",
                    "style": {
                        "bodyBackgroundColor": "#fff",
                        "bodyColor": "#333",
                        "themeBackgroundColor": "#77cab9",
                        "themeColor": "#fff",
                        "headerBackgroundColor": "#77cab9",
                        "headerColor": "#fff",
                        "errorColor": "red",
                        "successColor": "green",
                        "card": {
                            "padding": "",
                            "backgroundColor": "",
                            "borderRadius": '0px',
                        }
                    },
                    "data": {
                        "orderId": res.data.data.orderInfo.ORDER_ID,
                        "token": res.data.data.txnToken,
                        "tokenType": "TXN_TOKEN",
                        "amount": res.data.data.orderInfo.ORDER_TOTAL_AMOUNT,
                    },
                    "payMode": {
                        "labels": {},
                        "filter": {
                            "exclude": [
                                "WALLET",
                                "BANK_MANDATE",
                                "BANK_TRANSFER",
                                "EMI",
                                "EMI_DC",
                                "MGV",
                                "LOGIN",
                                "PPBL",
                                "PDC",
                                "SC",
                                "BALANCE",
                            ]
                        },
                        "order": [
                            "NB",
                            "CARD",
                            "UPI",
                        ]
                    },
                    "website": "WEB",
                    "flow": "DEFAULT",
                    "merchant": {
                        "mid": Payment_Gateway.MERCHANT_ID,
                        'name': 'ATUM Life',
                        "redirect": false,
                    },
                    "handler": {
                        "transactionStatus": function transactionStatus(paymentStatus) {
                            console.log("paymentStatus => ", paymentStatus);
                            window.Paytm.CheckoutJS.close();

                            // body = {
                            //     orderItems: Order_Item,
                            //     shippingAddress: Shipping_Address,
                            //     PREBOOK_AMOUNT: Attribute_Data.attributesInfo.PREBOOK_AMOUNT[0].ATTRIBUTE_VALUE,
                            //     DELIVERY_TYPE: "CUSTOMER_ADDRESS",
                            //     PAYMENT_STATUS: "SUCCESS",
                            //     PAYMENT_MODE: "ONLINE",
                            //     STATUS: "PREBOOK",
                            //     ORDER_AMOUNT: Attribute_Data.ORDER_TOTAL_AMOUNT,
                            //     DELIVERY_CHARGES: 0,
                            //     ORDER_TRACKER_ID: res.data.data.orderInfo.ORDER_ID,
                            //     TRANSACTION_TOKEN: "",
                            //     TRANSACTION_RESPONSE_TOKEN: "",
                            //     USER_ID: user_data.data.userData.ID,
                            //     ACTION: "PREBOOK",
                            //     // STORE_ID : SelectedstorepickupID
                            // };
                            if(paymentStatus.STATUS == 'TXN_SUCCESS'){
                            axios({
                                method: 'POST',
                                headers: Api_Headers,
                                url: GLOBAL_DOMAIN + '/v1/userorders',
                                data: OrderData,
                            }).then((res) => {
                                console.log('order placed', res.data.data.data);
                                history.push('/orderlist');
                                resetaddress();
                                // history.push(`/order/${res.data.data.data.ID}`)
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                        },
                        "notifyMerchant": function notifyMerchant(eventName, data) {
                            console.log("Closed", eventName, data);
                            window.Paytm.CheckoutJS.close();
                        },
                    },
                };

                if (window.Paytm && window.Paytm.CheckoutJS) {
                    window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                        console.log('Before JS Checkout invoke');
                        window.Paytm.CheckoutJS.invoke();
                    }).catch(function onError(err) {
                        console.log("Error => ", err);
                    });
                };
            }).catch((err) => {
                console.log(err); window.Paytm.CheckoutJS.invoke();
            });
        } else if (Paymentmethod == 'ATUM_WALLET') {

            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/userorders',
                data: OrderData,
            }).then((res) => {
                console.log('order placed', res.data.data.data);
                history.push('/orderlist');
                resetaddress();
            }).catch((err) => {
                console.log(err);
            })
        }
    };

    return (
        <section className='my-5 py-5 pre_bg_img'>
            <div className='container d-flex justify-content-center'>
                <div className='m-3 col-sm-6'>
                    <div>
                        <h4>Pre-Booking</h4>
                        <p className='fs-12'>Please Provide below details for Pre-booking Payment Initiation.</p>
                    </div>
                    <div>
                        {Attribute_Data ?
                            <Card className='card_prebooking'>
                                <Row>
                                    <Col xs={3}>
                                        <img
                                            alt="LOGO"
                                            src={IMG_GLOBAL + Attribute_Data.IMAGE_PATH1}
                                            width='100%'
                                            // height='50vh'
                                            className="d-inline-block align-top"
                                            style={{ height: '100px' }}
                                        />
                                    </Col>
                                    <Col xs={8}>
                                        <h4>{Attribute_Data.PRODUCT_NAME}</h4>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <p>{Attribute_Data.CATEGORY_DISPLAY_NAME}</p>
                                            </div>
                                            <div>
                                                {Attribute_Data &&
                                                    <div>
                                                        <div className='Attribute_color_card text-center p-1 d-flex'>
                                                            <p className='mr-2'>Color:</p>
                                                            <p className='Attribute_color m-0 d-inline-flex' style={{ backgroundColor: Attribute_Data.PRODUCT_COLOR }}></p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <p>Price : {Attribute_Data.DISCOUNT_PRICE}</p>
                                            <p>Prebooking Amount : <b>{Attribute_Data.attributesInfo.PREBOOK_AMOUNT[0].ATTRIBUTE_VALUE}</b></p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card> :
                            <div>
                                <p>Something Went Worng Please Try Again Sometime...</p>
                            </div>
                        }
                    </div>
                    <Form className='mt-2' onSubmit={AddressForm.handleSubmit}>
                        <Card className='card_prebooking'>
                            <Row>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="CUSTOMER_NAME" className="d-block mb-1">Customer Name</label>
                                        <InputText
                                            type='text'
                                            id='CUSTOMER_NAME'
                                            placeholder='Enter Your Customer Name'
                                            value={AddressForm.values.CUSTOMER_NAME}
                                            onChange={AddressForm.handleChange}
                                            className={(classNames({ 'p-invalid': isFormFieldValid('CUSTOMER_NAME') }))}
                                        ></InputText>
                                        <small>{getFormErrorMessage('CUSTOMER_NAME')}</small>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="MOBILE_NUMBER" className="d-block mb-1">Mobile Number</label>
                                        <InputText
                                            type='text'
                                            id='MOBILE_NUMBER'
                                            // className='form-control'
                                            placeholder='Enter Your Mobile Number'
                                            value={AddressForm.values.MOBILE_NUMBER}
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            maxLength={10}
                                            minLength={10}
                                            // onChange={(e) => setFirstName(e.target.value)}
                                            onChange={AddressForm.handleChange}
                                            className={(classNames({ 'p-invalid': isFormFieldValid('MOBILE_NUMBER') }))}
                                        ></InputText>
                                        <small>{getFormErrorMessage('MOBILE_NUMBER')}</small>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="ADDRESS_LINE1" className="d-block mb-1">Address 1</label>
                                        <InputText
                                            type='text'
                                            className={(classNames({ 'p-invalid': isFormFieldValid('ADDRESS_LINE1') }))}
                                            id='ADDRESS_LINE1'
                                            placeholder='Enter your Address Line 1'
                                            value={AddressForm.values.ADDRESS_LINE1}
                                            // onChange={(e) => setFirstName(e.target.value)}
                                            onChange={AddressForm.handleChange}
                                        ></InputText>
                                        <small>{getFormErrorMessage('ADDRESS_LINE1')}</small>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="ADDRESS_LINE2" className="d-block mb-1">Address 2</label>
                                        <InputText
                                            type='text'
                                            id='ADDRESS_LINE2'
                                            className={(classNames({ 'p-invalid': isFormFieldValid('ADDRESS_LINE2') }))}
                                            placeholder='Enter your Address Line 2'
                                            value={AddressForm.values.ADDRESS_LINE2}
                                            // onChange={(e) => setFirstName(e.target.value)}
                                            onChange={AddressForm.handleChange}
                                        ></InputText>
                                        <small>{getFormErrorMessage('ADDRESS_LINE2')}</small>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="CITY" className="d-block mb-1">City</label>
                                        <InputText
                                            type='text'
                                            id='CITY'
                                            className={(classNames({ 'p-invalid': isFormFieldValid('CITY') }))}
                                            placeholder='Enter your City'
                                            value={AddressForm.values.CITY}
                                            // onChange={(e) => setFirstName(e.target.value)}
                                            onChange={AddressForm.handleChange}
                                        ></InputText>
                                        <small>{getFormErrorMessage('CITY')}</small>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="STATE" className="d-block mb-1">State</label>
                                        <InputText
                                            type='text'
                                            id='STATE'
                                            className={(classNames({ 'p-invalid': isFormFieldValid('STATE') }))}
                                            placeholder='Enter your State'
                                            value={AddressForm.values.STATE}
                                            // onChange={(e) => setFirstName(e.target.value)}
                                            onChange={AddressForm.handleChange}
                                        ></InputText>
                                        <small>{getFormErrorMessage('STATE')}</small>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="COUNTRY" className="d-block mb-1">Country</label>
                                        <InputText
                                            type='text'
                                            id='COUNTRY'
                                            className={(classNames({ 'p-invalid': isFormFieldValid('COUNTRY') }))}
                                            placeholder='Enter Your Country'
                                            value={AddressForm.values.COUNTRY}
                                            // onChange={(e) => setFirstName(e.target.value)}
                                            onChange={AddressForm.handleChange}
                                        ></InputText>
                                        <small>{getFormErrorMessage('COUNTRY')}</small>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="field mb-3">
                                        <label htmlFor="PINCODE" className="d-block mb-1">Pincode</label>
                                        <InputText
                                            type='text'
                                            id='PINCODE'
                                            className={(classNames({ 'p-invalid': isFormFieldValid('PINCODE') }))}
                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            maxLength={6}
                                            minLength={6}
                                            placeholder='Enter Your PINCODE'
                                            value={AddressForm.values.PINCODE}
                                            // onChange={(e) => setFirstName(e.target.value)}
                                            onChange={AddressForm.handleChange}
                                        ></InputText>
                                        <small>{getFormErrorMessage('PINCODE')}</small>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <Card className='mt-2 card_prebooking'>
                            <div className='Paymentmethod_card'>
                                <div className="field-radiobutton my-3">
                                    <RadioButton inputId="COD" name="Paymentmethod" value="COD" disabled={true}
                                        onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')}
                                        checked={Paymentmethod === 'COD'} />
                                    <label htmlFor="COD" className='mx-3'>Cash On Delivery</label>
                                </div>
                                <div className="field-radiobutton my-3 d-flex justify-content-between">
                                    <div>
                                        <RadioButton inputId="ATUM_WALLET" name="Paymentmethod" value="ATUM_WALLET"
                                            onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')}
                                            checked={Paymentmethod === 'ATUM_WALLET'} />
                                        <label htmlFor="ATUM_WALLET" className='mx-3'>Atum Wallet
                                            {/* <b>({User_Info.TOTAL_WALLET_AMOUNT})</b> */}
                                        </label>
                                    </div>
                                </div>
                                <div className="field-radiobutton my-3">
                                    <RadioButton inputId="ONLINE" name="Paymentmethod" value="ONLINE"
                                        onChange={(e) => setpaymentmethod(e.value) && setpayment_status('SUCCESS')}
                                        checked={Paymentmethod === 'ONLINE'} />
                                    <label htmlFor="ONLINE" className='mx-3'>Pay Now</label>
                                </div>
                            </div>
                            {Paymentmethod == 'ATUM_WALLET' && parseFloat(User_Info.TOTAL_WALLET_AMOUNT) <= Attribute_Data.attributesInfo.PREBOOK_AMOUNT[0].ATTRIBUTE_VALUE ?
                                <div>
                                    <span className='' style={{ color: 'red' }}><b>{User_Info.TOTAL_WALLET_AMOUNT}</b> Please upgrade the amount from <Link to='/wallet'>Wallet</Link></span>
                                </div> : ''
                            }
                        </Card>
                        <Button type='submit' className='px-5 mt-2 btn_p w-100' disabled={Paymentmethod == 'ATUM_WALLET' && parseFloat(User_Info.TOTAL_WALLET_AMOUNT) <= Attribute_Data.attributesInfo.PREBOOK_AMOUNT[0].ATTRIBUTE_VALUE} variant="success" style={{ borderRadius: '25px' }}>Submit </Button>
                    </Form>
                </div>
            </div>
        </section>
    )
}
export default PrebookingOrder