import React, { useEffect, useState } from "react";
import axios from "axios";
import { GLOBAL_DOMAIN, Api_Headers } from '../../Config/Config';
import { Container, Row, Col } from "react-bootstrap";

import './Footer.css';

const ContactScreen = () => {

  const [Store_List, setStore_List] = useState([]);

  useEffect(() => {
    getStoredata();
    window.scrollTo(0, 0);
  }, []);

  const getStoredata = () => {
    axios({
      method: 'GET',
      headers: Api_Headers,
      url: GLOBAL_DOMAIN + '/v1/stores?type=list&limit=20'
    }).then((res) => {
      setStore_List(res.data.rows);
    });
  }
  return (
    <div className="contact-head">
      <h1 className="contact-h1">Contact Us</h1>
      <p className="contact-p m-0">Share your query and ideas with us!</p>
      <p className="contact-p">Mail us : info@atumlife.com</p>
      <Container className="contact-contain">
        <Row>
          {Store_List.map(item => {
            return (
              <React.Fragment>
                <Col className="contact-end contact-item" xs={12} md={2}>{item.CITY}</Col>
                <Col className="contact-end contact-item" xs={12} md={6}>{item.STORE_NAME}, {item.ADDRESS_LINE1}, {item.ADDRESS_LINE2}, {item.CITY}, {item.COUNTRY_NAME}, {item.PINCODE}.</Col>
                <Col className="contact-end contact-item" xs={12} md={2}>  Contact: (+91) {item?.CONTACT_NUMBER}
                  {/* <iframe width={'150px'} src = {'https://maps.google.com/maps?q='+ item.LATITUDE + ',' + item.LONGITUDE + '&embedded=true&hl=es;z=14&amp;output=embed'} frameborder="0" allowfullscreen></iframe> */}
                </Col>
              </React.Fragment>
            )
          })}
        </Row>
      </Container>
    </div>
  );
};

export default ContactScreen;