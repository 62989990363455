import { Button, Container } from "react-bootstrap";
import React, { Component, useState, useEffect,useRef } from 'react';
import axios from 'axios';
import './Productlist.css'
import { Card } from 'primereact/card';
import { useHistory, Link } from 'react-router-dom';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers } from "../../../Config/Config";
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';

const Productlist = () => {
    const history = useHistory();
    const [productData, setproductdata] = useState([]);
    const [selecteddata, setseleteddata] = useState();
    // const getCatID = window.localStorage.getItem('CATID');
    // const [setSecletedCategories, selectedcategories] = useState()
    const [isSkeleton, setisSkeleton] = useState(true);
    const [isSkeletonloop, setisSkeletonloop] = useState([1, 2, 3, 4, 6, 7, 8, 9]);
    const toast = useRef(null);
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));

    const getproductlist = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/products?type=list&limit=12&home=true',
        }).then((res) => {
            console.log('Product Data :', res.data);
            const productdata = res.data.rows;
            setproductdata(productdata);
            setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getproductlist();
    }, []);

    const gotoproductview = (e) => {
        const productdataa = JSON.stringify(e);
        setseleteddata(productdataa)
        localStorage.setItem('ProductID', e.ID);
        console.log(productdataa);
        history.push('/product/' + e.ID);
    }
    const showtoast = () => {
        toast.current.show({severity:'success', summary: 'Successfully added to cart', life: 3000});
    }
    const AddtoCart = (item) => {
        if(!user_data){
            history.push('/login');
        }
        axios({
            method: 'post',
            headers: Api_Headers,
            data:{
                "USER_ID":user_data.data.userData.ID,
                "PRODUCT_ID":item.ID,
                "QUANTITY":1,
                "PRODUCTS_QUANTITY": item.QUANTITY,
            },
            url: GLOBAL_DOMAIN + '/v1/usercart/',

        }).then((res) => {
            // console.log('CART Dataaa :', res.data);
            showtoast();

        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <section className="mx-5 mt-4">
            <Toast ref={toast} />
            {/* <div className="mt-5">
                <div className='mb-4'>
                    <h4>Categories</h4>
                </div>
                <Carousel value={categories_data} numVisible={9} numScroll={1} showIndicators={false} responsiveOptions={responsiveOptions}
                    itemTemplate={productTemplate} />
            </div> */}
            <div className="mt-5 d-flex justify-content-between">
                <h4 className="">Discounted Products</h4>
                <h6 className="color_head"><Link to='/dashboard' className="color_head text-decoration-none" onClick={()=> window.localStorage.removeItem('search_data')}>View More</Link></h6>
                
            </div>
            {isSkeleton ? <div className="row">{isSkeletonloop.map(() => {
                return(
                    <div className="col-sm-6 col-md-6 col-lg-3 mb-3">
                        <Skeleton width="100%" height="200px" />
                    </div>
                )
            })} </div> :
            <div className="row mt-4">
                {productData.map(item => {
                    const header = (
                        <img alt="Card" onClick={() => gotoproductview(item)} className="img-fluid card-img-top" src={IMG_GLOBAL + item.IMAGE_PATH1} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                    );
                    const pricedata = (
                        <div className='status_button'>
                            {item.DISCOUNT_PERCENTAGE === 0 && <p className="color_green">&#8377;{item.PRICE}</p>}
                            {item.DISCOUNT_PERCENTAGE > 0 && <p className="color_green"> &#8377;{item.DISCOUNT_PRICE} <del className="color_red">&#8377;{item.PRICE}</del></p>}
                        </div>
                    );
                    const Atum_coins = (
                        <div className='status_button'>
                            {/* {item.ELIGIBLE_ATUM_COINS === 0 && <p className="color_head">&#8377;{item.PRICE}</p>} */}
                            {item.CATEGORY == "ELECTRIC_VEHICLES" ? <p className="color_head m-0 fs-7"> *Excluding RTO, Insurance, GST and Transport Charges</p> : 
                            <p className="color_head m-0 fs-7">{item.ELIGIBLE_ATUM_COINS > 0 && <span>*Get {item.ELIGIBLE_ATUM_COINS} ATUM COINS Cashback</span>} </p>}
                        </div>
                    );
                    return (
                        <div className="col-sm-6 col-md-6 col-lg-3 mb-3">
                            <Card header={header} style={{ height: '340px' }}>
                                <h6 className="text-truncate">{item.PRODUCT_NAME}</h6>
                                <div>{pricedata}</div>
                                <div className='row'>
                                <div className="fs-12 col-sm-7">{Atum_coins}</div>
                                <div className="text-end col-sm-5">
                                    <Button className="btn_p fs-10" onClick={() => AddtoCart(item)} >ADD TO CART</Button>
                                </div>
                                </div>
                            </Card>
                        </div>
                    )
                }
                )}
            </div>
            }
        </section>
    )
}

export default Productlist;