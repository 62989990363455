import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import './Header_Carousel.css';
import { IMG_GLOBAL } from "../../Config/Config";

const Header_Carousel = (props) => {

    const [banner_data, setbanners] = useState([]);

    useEffect(() => {
        setbanners(props.BannerData);
    }, []);

    return (
        <section className="container">
            <Carousel pause="hover"  indicators={banner_data.length <= 1 ? false : true } controls={banner_data.length <= 1 ? false : true } className="Header_Carousel_Banner" variant='dark'>
                {banner_data.length > 0 && banner_data.map((items, index) =>
                    <Carousel.Item key={index}>
                        <img src={IMG_GLOBAL + items.IMAGE_PATH} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt="Banner Image" style={{ width: '100%', height: '280px', borderRadius: '5px' }} />
                    </Carousel.Item>
                )}
            </Carousel>
        </section>
    )
}

export default Header_Carousel