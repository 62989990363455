// const process = {
//     env: {
//         REACT_APP_GLOBAL_DOMAIN: 'https://devapi.atumlife.com/api',
//         REACT_APP_IMG_DOMAIN: 'https://vilassetmanagement.blob.core.windows.net/atumlifeapp/',
//         REACT_APP_PG_MERCHANT_ID: 'ATUMLI31629784468922',
//         REACT_APP_PG_MERCHANT_KEY: 'jHy_AN%j#MzTIT7D',
//         REACT_APP_PG_CALLBACK_URL: 'https://securegw-stage.paytm.in/theia/paytmCallback?orderId=',
//         REACT_APP_PG_HOST_NAME: 'securegw-stage.paytm.in',
//         REACT_APP_PG_MERCHANT_URL: 'https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/ATUMLI31629784468922.js',
//     }
// }

// Devlopment URL
export const GLOBAL_DOMAIN = process.env.REACT_APP_GLOBAL_DOMAIN ? process.env.REACT_APP_GLOBAL_DOMAIN : 'https://devapi.atumlife.com/api';
// 'http://app.atumlife.com:3010/api';
// 'https://demoapi.atumlife.com/api';
// 'http://192.168.41.77:3001/api';
export const IMG_GLOBAL = process.env.REACT_APP_IMG_DOMAIN ? process.env.REACT_APP_IMG_DOMAIN : 'https://vilassetmanagement.blob.core.windows.net/atumlifeapp/';
// 'http://app.atumlife.com:3010/';
// 'https://demoapi.atumlife.com/';
// 'http://192.168.41.77:3001/';

export const PAYTM_MERCHANT_URL = process.env.REACT_APP_PG_MERCHANT_URL ? process.env.REACT_APP_PG_MERCHANT_URL : 'https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/ATUMLI31629784468922.js';

export const PAYTM_SCRIPT_ID = "PAYTM_INTEG";

export const Api_Headers = {
    "access-control-allow-origin": "*",
    "Content-type": "application/json; charset=UTF-8"
}
// Production URLS
// export const GLOBAL_DOMAIN =
//     'https://api.atumlife.com/api';

// export const IMG_GLOBAL =
//     'https://api.atumlife.com/';

// export const Payment_Gateway = {
//     "MERCHANT_ID":"ATUMLI36943616705688",
//     "MERCHANT_KEY":"h8S#FAtyS%RkAKzF",
//     "WEBSITE":"DEFAULT",
//     "HOST_NAME":"securegw.paytm.in",
//     "CALLBACK_URL":"https://securegw.paytm.in/theia/paytmCallback?orderId=",
//     "INDUSTRY_TYPE":"ECommerce"
// }

export const Payment_Gateway = {
    "MERCHANT_ID": process.env.REACT_APP_PG_MERCHANT_ID ? process.env.REACT_APP_PG_MERCHANT_ID : "ATUMLI31629784468922",
    "MERCHANT_KEY": process.env.REACT_APP_PG_MERCHANT_KEY ? process.env.REACT_APP_PG_MERCHANT_KEY : "jHy_AN%j#MzTIT7D",
    "WEBSITE": "DEFAULT",
    "HOST_NAME": process.env.REACT_APP_PG_HOST_NAME ? process.env.REACT_APP_PG_HOST_NAME : "securegw-stage.paytm.in",
    "CALLBACK_URL": process.env.REACT_APP_PG_CALLBACK_URL ? process.env.REACT_APP_PG_CALLBACK_URL : "https://securegw-stage.paytm.in/theia/paytmCallback?orderId=",
    "INDUSTRY_TYPE": "ECommerce"
}

//export const GLOBAL_DOMAIN = "https://atumlifeapptest-demoapi.azurewebsites.net/api";
//export const IMG_GLOBAL = "https://atumlifeapptest-demoapi.azurewebsites.net";

//export const GLOBAL_DOMAIN = "http://testapi.atumlife.com/api";
//export const IMG_GLOBAL = "http://testapi.atumlife.com";

export const REFARAL_GLOBAL_DOMAIN = process.env.REACT_APP_REF_GLOBAL_DOMAIN ? process.env.REACT_APP_REF_GLOBAL_DOMAIN : 'https://referrals.atumlife.com/api/';

