import React, {useEffect} from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import RecycleIcon from '../../Assets/icon1.1.png';
import SustainIcon from '../../Assets/icon2.2.png';
import EcoIcon from '../../Assets/icon3.3.png';
import HealthIcon from '../../Assets/icon4.4.png';
//import logo from '../../Assets/other-03.png';

export default function AboutScreen(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div className='about-head my-4'>
            <div className='about-heading'>
                <h3 className='about-heads1'><b>welcome to atumlife</b></h3>
                <h5 className='about-heads2'><i>Your one stop-shop for all things</i></h5>
            </div>
           
            <div className='about-heading-img'></div>

            <Container className='about-pads'>
                <Row>
                    <Col xs={12} md={3}>
                        <img src={RecycleIcon} />
                        <div className='about-icons'>Recycled</div>
                        <p>We showcase products made from recycled and upcycled materials such as plastic, plastic bottles making it easier for our planet.</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <img src={SustainIcon} />
                        <div className='about-icons'>Sustainable</div>
                        <p>Our product selection uses our precious resources judiciously, making it more sustainable for Mother Earth.</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <img src={EcoIcon} />
                        <div className='about-icons'>Eco Friendly</div>
                        <p>All our showcased products leave low carbon and water footprints making them friends of the environment.</p>
                    </Col>
                    <Col xs={12} md={3}>
                        <img src={HealthIcon} />
                        <div className='about-icons'>Health Friendly</div>
                        <p>Our selection of brands and products across the board are chemical-free, hazard-free and healthy for you and the planet.</p>
                    </Col>
                </Row>
            </Container>

            <div className='about-body'>
                <div className='about-body-img'>
                    <div className='px-4 text-center'>
                        <h3 className='color_head'><i>Our Bond With Sustainability</i></h3>
                        <p>“Go Green’, ‘Save the Planet’, ‘Eco-Friendly’... You’ve heard all the cliches and you’re probably left wondering - ‘But how?’ At ATUM Life, we’re addressing environmental issues from the grassroots-up and bringing you a complete lifestyle overhaul. From the house of Visaka, where creating innovative sustainable products is the norm, ATUM Life is a one-of-a-kind store that houses eco-friendly clothing, body care items, cosmetics, food, toys, you name it. It’s a one-stop-shop for all things sustainable. Come, join us in our journey for a greener everyday.</p>
                        <h3 style={{color: '#338d7e'}}><i>Our Selection</i></h3>
                        <p>
                            The A to Z of sustainability.<br></br>We carry a wide range of sustainable products.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};